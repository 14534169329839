import axios from 'axios'; 

const state={
    contructors:[], 
    contructorTypes:[], 
    employees:[],  
    employeeTypes:[], 
    customers:[], 
    units:[], 
    constructions:[],
    constructionsTree:[],
    expensesType:[],
    expensesTypeTree:[],
    expenseTypeProject:[],
    users:[],
    permissions:[],
    roles:[]
}

const  getters={
    contructors:(state)=>state.contructors, 
    contructorTypes:(state)=>state.contructorTypes, 
    customers:(state)=>state.customers,
    employees:(state)=>state.employees,
    employeeTypes:(state)=>state.employeeTypes, 
    units:(state)=>state.units, 
    constructions:(state)=>state.constructions, 
    constructionsTree:(state)=>state.constructionsTree, 
    expensesType:(state)=>state.expensesType, 
    expensesTypeTree:(state)=>state.expensesTypeTree, 
    expenseTypeProject:(state)=>state.expenseTypeProject,
    users:(state)=>state.users, 
    permissions:(state)=>state.permissions, 
    roles:(state)=>state.roles, 
}

const mutations= {
    setContructorsType:(state,contructorTypes)=>state.contructorTypes=contructorTypes, 
    setContructors:(state,contructors)=>state.contructors=contructors, 
    setEmployees:(state,employees)=>state.employees=employees, 
    setEmployeeTypes:(state,employeeTypes)=>state.employeeTypes=employeeTypes, 
    setCustomers:(state,customers)=>state.customers=customers, 
    setUnits:(state,units)=>state.units=units, 
    setConstructions:(state,constructions)=>state.constructions=constructions, 
    setConstructionsTree:(state,constructionsTree)=>state.constructionsTree=constructionsTree, 
    setExpensesType:(state,expensesType)=>state.expensesType=expensesType, 
    setExpensesTypeTree:(state,expensesTypeTree)=>state.expensesTypeTree=expensesTypeTree, 
    setExpensesTypeProject:(state,expenseTypeProject)=>state.expenseTypeProject=expenseTypeProject, 
    setUsers:(state,users)=>state.users=users, 
    setPermissions:(state,permissions)=>state.permissions=permissions, 
    setRoles:(state,roles)=>state.roles=roles, 
} 
const actions={ 
    f_constructor({commit}){
        axios.post("a/per?person_type_id=2")
        .then(res => { 
           
           commit('setContructors',res.data.persons)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },
    f_constructorTypes({commit}){
        axios.post("a/constructor-type/get")
        .then(res => {
           
            commit('setContructorsType',res.data.contractor_type)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },
    f_employee({commit}){
        axios.post("a/per?person_type_id=3")
        .then(res => { 
                
                commit('setEmployees',res.data.persons) 
        
        })
        .catch(err => {
            console.error(err); 
        }) 
    },
    f_employeeTypes({commit}){
        axios.post("a/emp-type/get")
        .then(res => { 
         commit('setEmployeeTypes',res.data.employee_types)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },
    f_customer({commit}){
        axios.post("a/per?person_type_id=1")
        .then(res => {
        
         commit('setCustomers',res.data.persons)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },
    f_unit({commit}){
        axios.post('a/unit/get')
        .then(res => { 
            commit('setUnits',res.data.units)
           
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_constructions({commit}){
        axios.get('a/con-cat/all')
        .then(res => { 
            
            commit('setConstructions',res.data.construction_categories)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_constructionsTree({commit}){
        axios.get('a/con-cat/tree')
        .then(res => {  
            commit('setConstructionsTree',res.data.construction_categories)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_expensesType({commit}){
        axios.post('a/exp-type/get ')
        .then(res => {   
            commit('setExpensesType',res.data.expense_type)
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    f_expensesTypeProject({commit}){
        axios.get('a/exp-type/expense_projects_arr')
        .then(res => {  
            
            commit('setExpensesTypeProject',res.data)
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    f_expensesTypeTree({commit}){
        axios.get('a/exp-type/tree')
        .then(res => {   
            console.log(res.data.expense_type);
            commit('setExpensesTypeTree',res.data.expense_type)
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    f_user({commit}){
        axios.get('a/user/get')
        .then(res => {   
            commit('setUsers',res.data.users)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_role({commit}){
        axios.get('a/role')
        .then(res => {   
            
            commit('setRoles',res.data.roles)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_roleNames({commit}){
        axios.get('a/role/name')
        .then(res => {   
            
            commit('setRoles',res.data.roles)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_permissions({commit}){
        axios.get('a/role/permissions')
        .then(res => {   
            

            commit('setPermissions',res.data.permissions)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    
}

export default {
    getters,
    state,
    actions,
    mutations
}