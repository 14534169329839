<template>
<v-container grid-list-xs>
  <v-stepper color="green" class="mt-4" v-model="step">
    <v-stepper-header class="bg-main" >
      <v-stepper-step
      
        color="green" 
        :complete="step > 1"
        step="1"
      >
        معلومات المالك 
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
         color="green" 
        :complete="step > 2"
        step="2"
      >
        معلومات المشروع
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step color="green"  step="3">
         تم الحفظ 
      </v-stepper-step>
    </v-stepper-header>
    <v-container fluid>
      <customer-info v-if="step==1" @next="customerPage" />
      <AddProjectInfo v-if="step==2" @next="projectPage"  :customer_id="customer_id" />
      <AddProjectContract v-if="step==3" @next="save" :project_id="project_id" />
    </v-container>
  </v-stepper>
</v-container>
</template>
<script>
import customerInfo from '@/components/newProject/customerInfo.vue'
import AddProjectInfo from '@/components/newProject/addProjectInfo.vue'
import AddProjectContract from '@/components/newProject/addProjectContract.vue' 
  export default {
  components: { customerInfo, AddProjectInfo, AddProjectContract },
      name:"newProject",
    data () {
        return { 
            step:1,
            customer_id:''
      }
    },
    methods:{ 
      customerPage(e){
        if(e)
         this.customer_id=e;
         
         this.step=2;
      },
      projectPage(e){
        if(e)
         this.project_id=e;

        this.step=3
      },
      save(){
        
      }
    }
  }
</script>