<template>
<v-container fluid>
    <v-dialog v-model="dialogSorting" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                البحث حسب
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="searchExpenses.title" label="الفقرة" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete small outlined auto-select-first reverse v-model="searchExpenses.is_cash" :items="cash" item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete outlined v-model="searchExpenses.is_complete" :items="complete" item-text="name" item-value="id" label="مكتمل">
                            </v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.person_ids" :items="contructors_n" item-text="name" item-value="person_id" label="المتعهد"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.construction_category_ids" :items="constructions" item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.expense_type_ids" :items="expenseTypeProject" item-text="name" item-value="id" label="التبويب"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.from_invoice" label=" من تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.to_invoice" label="الى تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                             <v-checkbox label="اظهار المبلغ المدور" v-model="searchExpenses.is_revolved_balance" ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                        
                             <v-checkbox label="تفاصيل السلف" v-model="searchExpenses.is_with_not_exported" ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogSorting=!dialogSorting" dark color="red">الغاء</v-btn>
                <v-btn dark class="mx-5" @click="searchDefault" color="red">تفريغ <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="searchEx()" color="success">بحث</v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>

    <template>
        <!-- main table -->
        <v-data-table :headers="headers"   show-expand :items="projectExpenses" show-group-by class="my_btn bg-main" :footer-props="{
        'items-per-page-text':'عدد الصفحات',
          'next-icon':'$prev',
          'prev-icon':'$next', 
        }">
            <template v-slot:top>
                <v-toolbar flat class=" bg-main">
                    <!-- header -->
                    <v-toolbar-title v-if="projectExpenses[0]">مصروفات {{projectExpenses[0].project.name}}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="success" small class="my_btn" @click="dialogSorting=!dialogSorting">
                        <v-icon>mdi-sort-bool-ascending-variant</v-icon>
                    </v-btn> 
                                       <div id="dateRange">
                         <span class="mx-2" v-if="searchExpenses.from_invoice"> من {{searchExpenses.from_invoice}}</span>  <span class="mx-2"  v-if="searchExpenses.to_invoice"> الى {{searchExpenses.to_invoice}}</span>
                     </div>
                   
                    <v-spacer></v-spacer> 
                    <!-- print btn -->
                    <v-btn class="mb-2 mx-2 my_btn elevation-0" color="main" @click="print()">
                        <v-icon color="blue">mdi-printer-outline</v-icon>
                    </v-btn> 
                        <!-- <v-card>   
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title> 
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field outlined v-model="editedItem.title" :rules="rules.required" required label="الفقرة" reverse>
                                            </v-text-field>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete outlined auto-select-first reverse v-model="editedItem.is_cash" :items="cash" :rules="[v => (!!v || v==0 )|| 'ادخل الدفع']" required item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete reverse outlined v-model="editedItem.is_complete" :items="complete" :rules="[v => (!!v || v==0 ) || 'ادخل الحقل']" required item-text="name" item-value="id" label="مكتمل">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete reverse outlined v-model="editedItem.person_id" :items="contructors_n" item-text="name" item-value="person_id" label="المتعهد"></v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete reverse outlined v-model="editedItem.construction_category.id" :items="constructions" item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete reverse outlined v-model="editedItem.expense_type.id" :items="expenseTypeProject" :rules="[v => !!v || 'ادخل التبويب']" required item-text="name" item-value="id" label="التبويب"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field reverse outlined v-model="editedItem.out_bill_number" label="رقم الوصل" type="numper"></v-text-field>
                                        </v-col>
                                        
                                        <v-col dir="ltr" v-if="editedItem.is_cash" cols="12" sm="6" md="4">
                                            <v-autocomplete reverse outlined v-model="editedItem.safe_id" :items="my_safes"
                                                :rules="[v => !!v || 'اختر القاصه']" required item-text="name" item-value="id" label="القاصة ">
                                            </v-autocomplete>
                                            </v-col>
                                    </v-row>
                                    <v-divider class="my-3" v-if="editedItem.is_cash && editedIndex==-1"></v-divider>
                                    <v-row v-if="editedItem.is_cash==1  && editedIndex==-1">
                                        <v-col dir="ltr" cols="12" sm="6" md="4">

                                            <v-autocomplete reverse outlined v-model="editedItem.unit" :items="units" item-text="name" item-value="id" label="الوحدة "></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field reverse outlined v-model="editedItem.invoice_date" label="تاريخ الدفع" type="date"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field outlined v-model="editedItem.length" label="الطول" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field outlined v-model="editedItem.width" label="العرض" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field outlined v-model="editedItem.height" label="الارتفاع" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field reverse outlined v-model="editedItem.unit_price" label="سعر الوحدة" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field reverse outlined v-model="editedItem.count" label="الكمية" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <h3 v-if="editedItem.unit_price">المبلغ الكلي:{{total}} </h3>
                                            <v-text-field v-else outlined reverse v-model="write_total" label="المبلغ" type="numper"></v-text-field>
                                        </v-col>

                                    </v-row>

                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" large text @click="close"> إلغاء </v-btn>
                                <v-btn color="green white--text" large :disabled="!formIsValid" type="submit" @click="save"> حفظ </v-btn>
                            </v-card-actions>
                        </v-card>  -->
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <!-- delete dialog -->
                            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template> 
            <template v-slot:[`group.header`]="{headers, isOpen, toggle ,group,remove}">
                <th class="text-right" :colspan="headers.length">
                    <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{group}}
                    <v-icon @click="remove">mdi-close</v-icon>
                </th>
            </template>
            <!-- more detials -->
            <template v-slot:expanded-item="{ headers , item }">
                <!-- {{ isOpen ? '' : toggle() }} -->
                <td  :colspan="headers.length">
                    <!-- تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b>  --> 
                    <v-data-table id="table1" class="ma-2" :headers="[
                    { text: 'الفقرة', value: 'title', align: 'start', sortable: false },
                    { text: 'المبلغ ', value: 'total', sortable: false }, 
                    { text: 'التاريخ', value: 'invoice_date', sortable: false },
                    { text: 'رقم الفاتورة', value: 'bill_number', sortable: false },
                    // { text: 'الملاحظات', value: 'notes', sortable: false },
                    ]" :search="search" :items="item.invoice.not_exported"
                        hide-default-footer disable-pagination>
                        <template v-slot:top>
                            <!-- header -->
                            <br>
                            <v-toolbar-title class="p_right me-3"><b>المستحقات </b></v-toolbar-title>
                            <br>
                        </template> 
                        <template v-slot:no-data>
                            لايوجد
                        </template> 
                        </v-data-table>
                </td>
            </template>
           
            <!-- actions of table -->
            <template v-slot:[`item.operation`]="{item}">
                <v-icon color="" class="mr-2"   @click="push(item)"> mdi-pencil </v-icon>
                <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
                <v-icon color="blue" @click="openExpense(item)"> mdi-open-in-new</v-icon>
            </template>
            <template v-slot:no-data>
                لا توجد بيانات
            </template>
        </v-data-table>
        <v-row class="my-5 text-center" dir="ltr"> 
            <v-col cols="12" md="4" class="elevation-0"> 
                <v-alert type="red" class="my_btn" border="left">
                    المبلغ الكلي المصروف : {{projectExpensesReport.total_export_sum}}
                </v-alert>
            </v-col>
        </v-row>
        <v-expansion-panels accordion>

            <v-expansion-panel>
                <v-expansion-panel-header>الخلاصة </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-alert type="info" class="my_btn" border="right">
                        المبلغ الكلي المقبوض: {{projectExpensesReport.total_import_sum}}
                    </v-alert> 
                    <v-alert v-if="searchExpenses.is_revolved_balance" type="info" class="my_btn" border="right">
                        المبلغ المدور: {{projectExpensesReport.revolved_balance}}
                    </v-alert> 
                    <v-alert type="info" class="my_btn" border="right">
                        فائدة المكتب: {{projectExpensesReport.company_profit_period}}
                    </v-alert>
                    <v-alert type="success" class="my_btn" border="right">
                        الخلاصة : {{projectExpensesReport.conclusion}}
                    </v-alert> 
                </v-expansion-panel-content>
            </v-expansion-panel>

            </v-expansion-panels>
    </template> 
    <!-- to print -->
    <v-dialog v-model="dialogPrint">
        <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>

        <h3 v-if="projectExpenses[0]" id="p_title" class="p_center">مصروفات مشروع {{projectExpenses[0].project.name}} </h3>
        <div>
            <v-data-table id="table"  show-expand  :expanded.sync="projectExpenses" :headers="headersPrint" :items="projectExpenses" class="elevation-1" hide-default-footer disable-pagination>
                
                <template v-slot:expanded-item="{ item }">
                  
                <td v-if="item.invoice.not_exported" :colspan="headers.length"> 

                       <table class="bg-gray"> 
                        <tr  v-for="i in item.invoice.not_exported" :key="i">
                            <td>{{ i.title }}</td>
                            <td>{{ i.total }}</td>
                            <td>{{ i.invoice_date }}</td>
                            <td>{{ i.bill_number }}</td>
                            <!-- <td>{{ i.notes }}</td> -->
                             
                        </tr> 
                        </table>
                    <!-- <v-data-table  id="table1" class="ma-2" :headers="[
                    { text: 'الفقرة', value: 'title', align: 'start', sortable: false },
                    { text: 'المبلغ ', value: 'total', sortable: false }, 
                    { text: 'التاريخ', value: 'invoice_date', sortable: false },
                    { text: 'رقم الفاتورة', value: 'bill_number', sortable: false },
                    { text: 'الملاحظات', value: 'notes', sortable: false },
                    ]" :search="search" :items="item.invoice.not_exported"
                        hide-default-footer disable-pagination>
                        <template v-slot:top> 
                           تفاصيل الفقره:
                        </template>
                        <template v-slot:[`item.`]="{item}"> 
                            <span  >{{item.invoice_date[1].slice(0,10)}}</span> 
                        </template>
                        <template v-slot:no-data>
                            لايوجد
                        </template>

                        </v-data-table> -->
                </td>
            </template>
           </v-data-table>
        </div>

        <v-container class="p_center  " id="print2">
            <div>
                <h2>الخلاصة</h2>
                <table>
                    <tr v-if="searchExpenses.is_revolved_balance">
                        <td> المبلغ المدور</td>
                        <td>{{projectExpensesReport.revolved_balance}}</td>
                    </tr>
                    <tr>
                        <td> المبلغ الكلي المقبوض</td>
                        <td> {{projectExpensesReport.total_import_sum}}</td>
                    </tr>
                    <tr>
                        <td> المبلغ الكلي المصروف</td>
                        <td>{{projectExpensesReport.total_export_sum}}</td>
                    </tr>
                    <tr>
                        <td> فائدة المكتب</td>
                        <td> {{projectExpensesReport.company_profit_period}}</td>
                    </tr>
                    
                    <tr>
                        <td>الخلاصة </td>
                        <td>{{projectExpensesReport.conclusion}}</td>
                    </tr>
                </table>
            </div>
        </v-container>
        <div id="geter_reco" style="justify-content: space-around; display: flex;">
          
                  <div   class="p_center"> 
                        <h4>المستلم</h4>
                        <h4 > 
                          <span> 2024/ &nbsp;   /  &nbsp; </span>  <span> تاريخ الاستلام </span>
                        </h4> 
                    </div>
                    <div  class="p_center"> 
                        <h4> مدير المكتب</h4>
                        <h4>وسام محمد حسن الكفائي</h4>
                    </div>
                    <div class="p_center">
                        <h4>م. الموقع</h4>
                    </div>  
                     <div class="p_center">
                        <h4>الحسابات</h4>
                    </div>   
        </div>
    </v-dialog>
     <!-- to print --> 
    <v-dialog v-model="dialogPrintSet" max-width="900px">
            <v-card>
                <v-card-title text class="text-h6">اختر الاعمده للطباعة </v-card-title>
                <v-row class="mx-5">
                    <v-col class="mx-5" dir="ltr" cols="12" md="6">
                        <v-combobox reverse outlined v-model="headersPrint" :items="headers" multiple item-text="text" item-value="value" label="اختر العمود"></v-combobox>
                    </v-col> 
                    <v-col class="mx-5" dir="ltr" cols="12" md="6">
                        <v-combobox reverse outlined v-model="printingArray" :items="printingArrayChoies" multiple item-text="name" item-value="text" label="اختر باقي التفاصيل"></v-combobox>
                    </v-col> 
                </v-row>
                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="dialogPrintSet=!dialogPrintSet">الغاء</v-btn>
                  <v-btn rounded color="main" class="elevation-0 mb-2 mx-2  " @click="printOk()">
                    طباعة  
                        <v-icon color="blue">mdi-printer-outline</v-icon>
                  </v-btn> 
                  <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
</v-container>
</template>
<script>
import printing from '@/plugins/print'
import tools from '@/plugins/tools'
import router from '@/router'
import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex'
export default {
    name: 'projectExport',
    props: ['id'],
    data: () => ({
        dialog: false,
        dialogDelete: false,
        dialogPrint: false,
        write_total: '',
        dialogSorting: false, 
        rules: {
            required: [val => (val || '').length > 0 || 'ادخل الحقل '],
        },
        cash: [{
            id: 1,
            name: 'نقد'
        }, {
            id: 0,
            name: 'اجل'
        }],
        complete: [{
            id: 0,
            name: 'غير مكتمل'
        }, {
            id: 1,
            name: 'مكتمل'
        }],
        headers: [{
                text: 'الفقرة',
                value: 'title',
                align: 'start',
                groupable: false,
            }, 
            {
                text: 'المتعهد',
                value: 'person.name',
                sortable: false
            },
            {
                text: 'التبويب',
                value: 'expense_type.name'
            },
            {
                text: 'مرحلة العمل',
                value: 'construction_category.name',
                sortable: false
            },
            {
                text: 'التاريخ',
                value: 'first_invoice_date',
                groupable: false
            },
            {
                text: 'الكلي',
                value: 'total',
                groupable: false
            },
            {
                text: 'رقم الوصل',
                value: 'out_bill_number',
                sortable: false,
                groupable: false,
            },

            {
                text: ' العمليات',
                value: 'operation',
                sortable: false,
                groupable: false
            },
        ],
        headersPrint: [{
                text: 'الفقرة',
                value: 'title',
                align: 'start',
                sortable: false
            },
          
            // { text: 'مكتمل', value: 'is_complete', sortable: false  },
            {
                text: 'المتعهد',
                value: 'person.name',
                sortable: false
            },
            // { text: 'المحاسب', value: 'user.name', sortable: false  },
            {
                text: 'التبويب',
                value: 'expense_type.name',
                sortable: false
            },
            {
                text: 'مرحلة العمل',
                value: 'construction_category.name',
                sortable: false
            },
            {
                text: 'المبلغ ',
                value: 'total',
                sortable: false
            },
            {
                text: 'التاريخ',
                value: 'first_invoice_date',
                groupable: false
            },
          
            {
                text: 'رقم الوصل',
                value: 'out_bill_number',
                sortable: false,
                groupable: false,
            },

        ],
        editedIndex: -1,
        editedItem: {
            title: '', 
          is_cash: 'null',
            out_bill_number: '',
            is_complete: 'null',
            construction_category: {
                id: ''
            },
            project_id: '',
            expense_type: {
                id: ''
            },
            person_id: '',
            person: {
                id: ''
            },
            count: 0,
            unit_price: 0,
        },
        defaultItem: {
            title: '',
            out_bill_number: '', 
          is_cash: 'null',
            is_complete: 'null',
            construction_category: {
                id: ''
            },
            project_id: '',
            expense_type: {
                id: ''
            },
            person_id: '',
            person: {
                id: ''
            },
            count: 0,
            unit_price: 0,
        },
        dialogPrintSet:false,
        
        printingArrayChoies:[{ text:"dateRange" , name:'فترة زمية'},{text:"p_title",name:'العنوان'},{ text:"table" , name:'الجدول'},{ text:"footer" , name:'مجموع المبلغ'},{ text:"print2" , name:'الخلاصة'},{ text:"geter_reco" , name:'توقيع المهندس'}],
        printingArray:[{ text:"dateRange" , name:'فترة زمية'},{text:"p_title",name:'العنوان'},{ text:"table" , name:'الجدول'},{ text:"footer" , name:'مجموع المبلغ'},{ text:"print2" , name:'الخلاصة'},{ text:"geter_reco" , name:'توقيع المهندس'}],
            
        searchExpenses: {
            is_cash: '',
            is_complete: '',
            construction_category_id: '',
            project_id: '',
            expense_type_id: '',
            person_id: '',
            user_id: '',
            from: '',
            to: '',
            is_with_not_exported:false
        },
        searchExpensesDefault: {
            is_cash: '',
            is_complete: '',
            construction_category_id: '',
            project_id: '',
            expense_type_id: '',
            person_id: '',
            user_id: '',
            from: '',
            to: '',
            is_with_not_exported:true
        }
    }),

    computed: {
        ...mapGetters(['my_safes','projectExpenses', 'projectExpensesReport',
            'constructions', 'contructors_n', 'expenseTypeProject', 'units'
        ]),
        total() {
            return this.editedItem.count * this.editedItem.unit_price
        },
        formTitle() {
            return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
        },
        formIsValid() {
            return (
                (Boolean(this.editedItem.title) &&
                    Boolean(this.editedItem.expense_type.id) &&
                    Boolean(this.editedItem.is_cash != 'null') &&
                    Boolean(this.editedItem.is_complete != 'null') &&
                    Boolean(this.editedItem.is_cash != 1) ||
                    Boolean(this.editedItem.title) &&

                    (   (Boolean(this.editedItem.is_cash == 1) && Boolean(this.editedItem.safe_id)  )&&
                        Boolean(this.editedItem.invoice_date) &&
                        Boolean(this.editedItem.is_complete != 'null') && 
                        Boolean(this.total || this.write_total)||Boolean(this.editedIndex>-1)
                    )
                )
            )
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    created() {
        tools.checkPermissions('expense-search')
        this.setProjectExpenses([])
    },

    mounted() {
        let params = {}
        params.project_id = this.id
        // params.expense_type_ids = [1,2,3,4,5,6,7]
        params.is_with_not_exported =1
        // params.is_revolved_balance=1
        this.f_projectExpenses(params)
        this.f_constructors_n();
        this.f_constructions();
        this.f_expensesTypeProject();
        this.f_unit();
        this.f_my_safes()

       

    },
    methods: {
        ...mapMutations(['setProjectExpenses']),
        ...mapActions(['f_my_safes', 'f_projectExpenses', 'addExpenses', 'addExpensesCash', 'editExpenses',
            'deleteExpenses', 'f_unit', 'f_constructors_n',
            'f_constructions', 'f_expensesTypeProject'
        ]),
        openExpense(item) {
            if(item.is_old)
            router.push('/oldExpensesDetials/' + item.id)
            else
            router.push('/expensesDetials/' + item.id)
        },
        push(item){ 
            if(item.is_old)
            router.push('/oldExpenceInfo/' + item.id)
            else
            router.push('/expenceInfo/' + item.id)
             
        },
        searchEx() {
            if (this.searchExpenses.is_with_not_exported)  
                this.searchExpenses.is_with_not_exported='1'
            else 
                this.searchExpenses.is_with_not_exported='0'



            this.searchExpenses.project_id = this.id 
            this.f_projectExpenses(this.searchExpenses)
            this.dialogSorting = false
            this.searchExpenses.is_with_not_exported=false
 
        },
        searchDefault() {
            this.searchExpenses = Object.assign({}, this.searchExpensesDefault)
        },
        print() {
            this.dialogPrintSet = true;
            
        },
        printOk() {
            this.dialogPrintSet = false;
            this.dialogPrint = true;
            printing(this.printingArray)
            setTimeout(()=>{

              this.dialogPrint = false;
            },200)
        },

        checkPermissions(per){tools.checkPermissions(per)},
        editItem(item) {
            this.checkPermissions('expense-update')
            this.editedIndex = this.projectExpenses.indexOf(item)
          this.editedItem = Object.assign({}, item)
            
            if (this.editedItem.person)
                this.editedItem.person_id = this.editedItem.person.id;
            else
                this.editedItem.person_id = null

            if (this.id)
                this.editedItem.project_id = this.id
            else
                this.editedItem.project_id = this.idImport

            this.dialog = true
        },

        deleteItem(item) {
            this.checkPermissions('expense-delete')
            this.editedIndex = this.projectExpenses.indexOf(item)
            this.editedItem.id = item.id
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            let params = {}
            params.expense_type_id = 1
            params.project_id = this.id
            params.toProject = true

            this.editedItem.fetch = params

            this.deleteExpenses(this.editedItem)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() { //save dialog add and edit
            this.editedItem.construction_category_id = this.editedItem.construction_category.id;
            this.editedItem.expense_type_id = this.editedItem.expense_type.id
            this.editedItem.project_id = this.$route.params.id

            if (this.editedItem.unit_price)
                this.editedItem.total = this.total
            else
                this.editedItem.total = this.write_total

            //to fetch data
            let params = {}
            params.expense_type_id = 1
            params.project_id = this.id
            params.toProject = true

            this.editedItem.fetch = params

            if (this.editedIndex > -1) {
                //update item    
                this.editExpenses(this.editedItem)
            } else {
                //add new item  
                if (this.editedItem.is_cash) {
                    this.addExpensesCash(this.editedItem)
                } else {
                    this.addExpenses(this.editedItem)
                }
            }
            this.close()
        },
    },
}
</script>
