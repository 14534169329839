<template>
  <v-container fluid> 
      <template>

<!-- main table -->
  <v-data-table   
    :headers="headers"
    :search="search"
    :items="contructorTypes"   
    class="elevation-0 my_btn bg-main"
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
  >
    <template v-slot:top>
      <v-toolbar
        flat class="bg-main"
      >
      <!-- header -->
        <v-toolbar-title >انواع المتعهدين </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- search -->
         <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details 
        ></v-text-field>
         <v-spacer></v-spacer>
        <!-- add and delete dialog -->
        <v-dialog 
          v-model="dialog"
          max-width="700px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary" 
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="checkPermissions('card-add')"
            >أضافة<v-icon>mdi-plus</v-icon>  
            </v-btn> 
            
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <!-- dialog add and edit  -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="rules.name"
                      required
                      label="الاسم"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close" > إلغاء </v-btn>
              <v-btn color="green"    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog  v-model="dialogDelete" max-width="500px">
          <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- actions of table -->
    <template  v-slot:[`item.operation`]="{item}" >
      <v-icon color="" class="mr-2" @click="editItem(item)" > mdi-pencil </v-icon>
      <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon> 
    </template>
    <template v-slot:no-data>
      <v-card
      loading
      ><v-btn > انتظر..... </v-btn>
      </v-card>
    </template>
  </v-data-table>
 </template>
</v-container>

</template>
<script>
import tools from '@/plugins/tools'
import { mapActions, mapGetters } from 'vuex' 
  export default {
    name:'contructorTypesCard',
    data: () => ({  
      dialog: false,
      dialogDelete: false,   
      search:'', 
      rules: { 
          name: [val => (val || '').length > 0 || 'ادخل الاسم '],  
        }, 
      headers: [
        { text: 'الاسم',value: 'name',align:'start',sortable: false,},  
        { text: 'العمليات', value: 'operation',align:'end', sortable: false },
      ], 
      editedIndex: -1,
      editedItem: {
        name: '', 
      },
      defaultItem: {
        name: '',
      }
    }),

    computed: {
      ...mapGetters(['contructorTypes']),
      formTitle () {
        return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
      },
      formIsValid () {
        return (
          Boolean(this.editedItem.name )  
        )
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {  
        this.f_constructorTypes()
    },  
    methods: {
      ...mapActions(['f_constructorTypes','deleteCard','addCard','updateCard']),
    
      editItem (item) {
        this.checkPermissions('card-update')
        this.editedIndex = this.contructorTypes.indexOf(item)
        this.editedItem = Object.assign({}, item) 
        this.dialog = true
      },

      deleteItem (item) {
        this.checkPermissions('card-delete')
        this.editedIndex = this.contructorTypes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      }, 
      deleteItemConfirm () {  
        let card=[]; card.type="constructor-type"
        card.id=this.contructorTypes[this.editedIndex].id
        this.deleteCard(card) 
        this.closeDelete()  
      },

      close () { 
        this.dialog = false 
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }) 
      },
      checkPermissions(per){tools.checkPermissions(per)},

      save () {//save dialog add and edit
        if (this.editedIndex > -1) { 
          //update item 
          let paramsToUpdate={} 
          paramsToUpdate.name= this.editedItem.name  

          let card=[];card.type='constructor-type'
          card.id=this.contructorTypes[this.editedIndex].id
          card.data=paramsToUpdate;
 
          this.updateCard(card)  
        } else {
          //add new item   
          let card=[];card.type='constructor-type' 
          card.data=this.editedItem; 
          this.addCard(card)  
        }
        this.close()
      },

    }, 
  }
</script>
