import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Print from 'vue-print-nb' 
import Toasted from 'vue-toasted';
 
// you can also pass options, check options reference below
// Vue.use(Toasted, Options)

Vue.use(Toasted)
Vue.config.productionTip = false 

new Vue({
  router, 
  Print,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
