<template>
    <v-container   fluid>  
    <template> 
  <!-- main table -->
    <v-data-table    
      :headers="headers"
      :search="search"  
      :items="safes.safes"    
       class="my_btn bg-main"
      :footer-props="{
            'items-per-page-text':'عدد العناصر',
              'next-icon':'$prev',
              'prev-icon':'$next', 
            }"
    >
    <template v-slot:[`item.accessed_users`]="{item}">
     
        <div v-for="( a , index ) in item.accessed_users" :key="index">
          {{ index+1 }} - {{ a.name }} 
        </div>
    </template>
      <template  v-slot:top>
        <v-toolbar
          flat  class=" bg-main"
        >
        <!-- header -->
          <v-toolbar-title>القاصات</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider> 
          
  
            <v-spacer></v-spacer>
          <!-- search -->
           <v-text-field 
              reverse
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details 
          >
          </v-text-field>
           <v-spacer></v-spacer>
          <!-- add and delete dialog -->
          <v-dialog 
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                reverse 
                color="blue"
                dark
                
                class="mb-2 my_btn elevation-0"
                v-bind="attrs"
                v-on="on"
                @click="checkPermissions('safe-add')"
              >أضافة<v-icon>mdi-plus</v-icon>  
              </v-btn> 
              <!-- print btn -->
              <v-btn 
                class="mb-2 mx-2 my_btn elevation-0" 
                color="main" 
  
                @click="print()"
              >  <v-icon color="blue" >mdi-printer-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <!-- dialog add and edit  -->
              <v-card-text>
                <v-container>
                  <v-row >
                    <v-col 
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field 
                        outlined 
                        v-model="editedItem.name"
                        :rules="rules.required"
                        required
                        label="اسم القاصة"
                        reverse
                      >   
                      </v-text-field>
                    </v-col>
                    <v-col dir="ltr" cols="12"  sm="6"  md="4" >
                      <v-autocomplete 
                        reverse
                        outlined
                        multiple
                        v-model="editedItem.accessed_users" 
                        :items="users_name" 
                        :rules="[v => !!v || 'اختر المستخدمين']" 
                        item-text="name"
                        item-value="id"
                        label="المستخدمين " 
                      ></v-autocomplete>
                     </v-col>
                      <v-col v-if="editedIndex==-1" cols="12"  sm="6"  md="4" >
                        <v-text-field
                          reverse
                          outlined
                          v-model="editedItem.balance"
                          label="المبلغ الافتتاحي" 
                          type="number"
                        ></v-text-field>
                      </v-col> 
                    </v-row> 
                   
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" large text @click="close" > إلغاء </v-btn>
                <v-btn color="green white--text" large    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog  v-model="dialogDelete" max-width="500px">
            <v-card>
              <!-- delete dialog -->
              <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      >
      <!-- actions of table -->
      <template  v-slot:[`item.operation`]="{item}" >
        <v-icon color="" class="mr-2" @click="editItem(item)" > mdi-pencil </v-icon>
        <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        لا توجد بيانات
      </template>
    </v-data-table>
    <v-row  class="my-5 text-center">
      <v-col cols="12" md="4"  class="elevation-0">
        <v-alert type="info" class="my_btn" border="right">
          مبلغ مجموع القاصات : {{safes.sum_balance}}
        </v-alert>
      </v-col>
    </v-row>
   </template>
  
  
   <!-- to print -->
   <v-dialog v-model="dialogPrint">
    <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
  
    <h3   id="p_title"  class="p_center">القاصات</h3> 
  
     <div  >
      <v-data-table 
      id="table" 
      :headers="headersPrint"
      :search="search"
      :items="safes.safes"   
       class="elevation-1" 
       hide-default-footer
       disable-pagination 
      > 
      <template v-slot:[`item.accessed_users`]="{item}">
     
     <div v-for="( a , index ) in item.accessed_users" :key="index">
       {{ index+1 }} - {{ a.name }} 
     </div>
 </template>
      </v-data-table>
    </div>
    
    <h3   id="p_footer"  class="p_right p_box"> المبلغ الكلي : {{safes.sum_balance}}</h3> 
   </v-dialog>
  </v-container>
  
  
  </template>
  <script>  
  import printing from '@/plugins/print';
  import tools from '@/plugins/tools';
  import { mapActions, mapGetters } from 'vuex'  
// import axios from 'axios';
    export default { 
      name:'safesPage',
      props:['id'],
      data: () => ({
        dialog: false,
        dialogDelete: false, 
        dialogPrint:false,  
        search:'',  
        rules: { 
            required: [val => (val || '').length > 0 || 'ادخل الحقل '],
          }, 
        headers: [
          { text: 'اسم',value: 'name',align:'start',sortable: false },   
          { text: 'مستخدمين', value: 'accessed_users' , groupable: false}, 
          { text: 'المبلغ', value: 'balance' , groupable: false},  
          { text: 'العمليات', value: 'operation' , groupable: false},  
        ],
        headersPrint: [  
          { text: 'اسم',value: 'name',align:'start',sortable: false },   
          { text: 'مستخدمين', value: 'accessed_users' , groupable: false,sortable: false}, 
          { text: 'المبلغ', value: 'balance' , groupable: false,sortable: false},  
        ],  
        editedIndex: -1,
        editedItem: {
            name: '',
            balance: '',
            accessed_users: '',  
        },
        defaultItem: {  
            name: '',
            balance: '',
            accessed_users: '',  
        },  
      }),
  
      computed: {
        ...mapGetters(['safes','users_name']), 
        formTitle () {
          return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
        },
        formIsValid () {
          return (
            Boolean(this.editedItem.name )  )
          }
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
      created(){

        this.f_safes() 
        this.f_users_name()
      }, 
      methods: { 
        ...mapActions(['f_safes','f_users_name','addCard','deleteCard','updateCard']),  
        print() {
           this.dialogPrint=true ;
           printing(["p_title","table",'p_footer'])
          }, 

          checkPermissions(per){tools.checkPermissions(per)},

        editItem (item) {
          this.checkPermissions('safe-update')

          this.editedIndex = this.safes.safes.indexOf(item)
          this.editedItem = Object.assign({}, item) 
          this.dialog = true
        },
  
        deleteItem (item) {
          this.checkPermissions('safe-delete')

          this.editedIndex = this.safes.safes.indexOf(item)
          this.editedItem.id = item.id
          this.dialogDelete = true

          // axios.get('a/safe/delete/3')
          // .then(res => {
          //   console.log(res)
          // })
          // .catch(err => {
          //   console.error(err); 
          // })
        },
        deleteItemConfirm () {
          let card={}
          card.main_id=this.$route.params.id
          card.id=this.editedItem.id
          card.type='safe'

          console.log(this.editedItem);
          this.deleteCard(card)  
         this.dialogDelete = false
  
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        }, 
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        save () {//save dialog add and edit
               
          if (this.editedIndex > -1) {

            console.log(this.editedItem);
            //update item    
              let card={} 
                 
              delete this.editedItem.balance  
              card.data=this.editedItem 
              card.id=this.editedItem.id
              card.type='safe'
              this.updateCard(card)
          } else { 
            //add new item  
              let card={} 
              card.data=this.editedItem 
              card.type='safe'
              this.addCard(card)
          }
          this.close()
        },
      },
    }
  </script>
  