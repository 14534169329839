import axios from 'axios';  
import Vue from 'vue';

const state={
    expense:[],   
}

const  getters={
    expense:(state)=>state.expense,   
}

const mutations= {
    setExpense:(state,expense)=>state.expense=expense,   
} 
const actions={ 
      addExpenses({dispatch},params){
        axios.post('a/ex/complete_store',params)
        .then(() => { 
            dispatch('oprationSuccess','تم الاضافة بنجاح')
             
            if(params.fetch)
            dispatch('f_projectExpenses',params.fetch)

            else { 
                dispatch('f_expenses',params.fetch)
            }
        })
        .catch(err => {
            console.error(err);   
            dispatch('oprationFailed','لم يتم الاضافة')  

        })
    },
    // addExpensesCash({dispatch},params){
    //     axios.post('a/ex/add-cash',params)
    //     .then(() => { 
    //         dispatch('oprationSuccess','تم الاضافة بنجاح')
    //     })
    //     .catch(err => {
    //         console.error(err);   
    //         dispatch('oprationFailed','لم يتم الاضافة')
    //     })
    // },
    editExpenses({dispatch},params){
        axios.post('a/ex/update/'+params.id,params)
        .then(() => {
              
            dispatch('oprationSuccess','تم التعديل بنجاح')
          

        })
        .catch(err => {
            console.error(err);   
            dispatch('oprationFailed','لم يتم التعديل')

        })
    },
    deleteExpenses({dispatch},params){
        axios.get('a/ex/delete/'+params.id)
        .then(() => {
            if(params.fetch.toProject)
            dispatch('f_projectExpenses',params.fetch)

            else { 
                dispatch('f_expenses',params.fetch)
            }
            dispatch('oprationSuccess','تم الحذف بنجاح')
         

        })
        .catch(err => {
            console.error(err); 
            if (err.response.status == 403) {
            dispatch('oprationFailed','لايمكن حذف العنصر ')
        }
            
        })
    },
    payAmonut({dispatch},data){
        axios.post('a/ex/pay_full_amount/'+data.id,data)
        .then(() => {
            dispatch('oprationSuccess','تم التسوية بنجاح')
        })
        .catch(err => {
            console.error(err); 
            dispatch('oprationFailed','لم يتم التسوية')
            
        })
    },
    getExpense({commit},id){
        axios.get('a/ex/show/'+id)
        .then(res => {
            
            commit('setExpense',res.data.expense)
        })
        .catch(err => {
            console.error(err);  
        }) 
    },
    addInvoice({dispatch},params){
        axios.post('a/invoice/add',params)
        .then(() => {
            dispatch('oprationSuccess','تم الاضافة بنجاح')
            dispatch('getExpense',params.expenses_id)
        })
        .catch(err => {
            console.error(err); 
            dispatch('oprationFailed','لم يتم الاضافة')
            dispatch('oprationFailed',err.response.data.message)
        })
    },
    editInvoice({dispatch},params){
        axios.post('a/invoice/update/'+params.id,params)
        .then(() => {
            
            dispatch('oprationSuccess','تم التعديل بنجاح')
            dispatch('getExpense',params.expenses_id)
        })
        .catch(err => { 
            console.error(err); 
            dispatch('oprationFailed','لم يتم التعديل')
        })
    },
    deleteInvoice({dispatch},params){
        axios.get('a/invoice/delete/'+params.id)
        .then(() => {
             
            if(params.fetch.toProject)
            dispatch('f_projectExpenses',params.fetch)

            else { 
                dispatch('f_expenses',params.fetch)
            }
            
            dispatch('oprationSuccess','تم الحذف بنجاح')
            dispatch('getExpense',params.expenses_id)
        })
        .catch(err => {
            console.error(err); 
            dispatch('oprationFailed','لم يتم الحذف')
        })

    },




    oprationSuccess(e,message){
        Vue.toasted.show(message,{type:'success',theme: "outline", 
            position: "bottom-right", 
            duration : 2000}); 
    },
    oprationFailed(e,message){
        Vue.toasted.show(message ,{type:'error',theme: "outline", 
            position: "bottom-right", 
            duration : 5000}); 
    }
  
}

export default {
    getters,
    state,
    actions,
    mutations
}