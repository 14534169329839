<template>
  <v-container fluid> 
      <template>
<!-- main table -->
  <v-data-table   
    :headers="headers"
    :search="search"
    :items="customers"   
    class="elevation-0 my_btn bg-main"
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
  >
    <template v-slot:top>
      <v-toolbar
        flat class="bg-main"
      >
      <!-- header -->
        <v-toolbar-title >المالكين</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- search -->
         <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details 
        ></v-text-field>
         <v-spacer></v-spacer>
        <!-- add and delete dialog -->
        <v-dialog 
          v-model="dialog"
          max-width="700px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary" 
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="checkPermissions('card-add')"
            >أضافة<v-icon>mdi-plus</v-icon>  
            </v-btn> 
            <!-- print btn -->
            <v-btn 
              class="mb-2 mx-2" 
              @click="print()"
            >  <v-icon >mdi-printer-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <!-- dialog add and edit  -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    reverse
                    outlined
                      v-model="editedItem.name"
                      :rules="rules.name"
                      required
                      label="الاسم"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                     reverse
                     outlined
                      v-model="editedItem.address"
                      :rules="rules.address"
                      required
                      label="العنوان"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    reverse
                    outlined
                      v-model="editedItem.phone"
                      :rules="rules.phone" 
                      required
                      label="رقم الهاتف"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    reverse
                    outlined
                      v-model="editedItem.national_id"
                      label="رقم الهوية"
                    ></v-text-field>
                  </v-col>
                  <v-col> 
                   <v-autocomplete
                    outlined
                    auto-select-first
                    reverse
                    v-model="editedItem.marital_status_id"
                    :items="marital_status" 
                    required
                    item-text="name"
                    item-value="id"
                    label="الحاله الزوجية"
                ></v-autocomplete>
                </v-col>
                  <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="editedItem.mother_name"
                    required
                    label="اسم الام"
                ></v-text-field>
                </v-col>
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    type="number"
                    v-model="editedItem.house_number"
                    required
                    label="رقم  الدار"
                ></v-text-field>
                </v-col>
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="editedItem.street"
                    required
                    label="الشارع"
                ></v-text-field>
                </v-col>
                 
                    
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    type="number"
                    v-model="editedItem.children_count"
                    required
                    label="عدد الاولاد"
                ></v-text-field>
                </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close" > إلغاء </v-btn>
              <v-btn color="green"    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog  v-model="dialogDelete" max-width="500px">
          <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- details dialog -->
        <v-dialog  v-model="dialogDetails" max-width="500px">
          <v-card class="text-center">
            <v-card-title text  >تفاصيل المالك</v-card-title>
            <v-card-text> 

              <img  class=" my-3" width="100px" height="100px"  :src="api+editedItem.image" alt="لايوجد صورة">
              <br> 
              <v-btn icon @click="deletePersonImage(editedItem.id)" color="error"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn icon @click="uploadImage()" color="success"><v-icon>mdi-upload</v-icon></v-btn>
               
              <hr>

              <div  class=" mt-3" >الاسم:  <b>{{editedItem.name}}</b></div>
              <div>العنوان: <b>{{editedItem.address}}</b></div>
              <div>الهاتف: <b>{{editedItem.phone}}</b></div>
              <div>الملاحظات: <b>{{editedItem.notes}}</b></div> 
              <hr>
              <div  class=" my-3" >المستندات:<v-btn x-small dark color="green"   @click="uploadDoc()">اضافة<v-icon>mdi-plus</v-icon></v-btn> 
              
               <div class="" v-for="item,i in editedItem.documents" :key="i">
               <b> {{i+1}} - {{item.title}}  <v-btn small icon color="red" @click="deleteDoc(item.id)" ><v-icon >mdi-delete</v-icon></v-btn> 
                                            <v-btn small icon color="success" :href="api+item.file" ><v-icon  color="success">mdi-download</v-icon></v-btn> 
               </b> 
               </div>
              </div>
              <!-- <div>النوع: <b>{{editedItem.contractor_type.name}}</b></div> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer> 
              <v-btn color="red darken-1" text @click="close">إغلاق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- actions of table -->
    <template  v-slot:[`item.operation`]="{item}" >
      <v-icon color="" class="mr-2" @click="editItem(item)" > mdi-pencil </v-icon>
      <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon>
      <v-icon color="blue"  @click="details(item)" > mdi-format-list-text </v-icon> 
    </template>
    <template v-slot:no-data>
      <v-card
      loading
      ><v-btn > انتظر..... </v-btn>
      </v-card>
    </template>
  </v-data-table>
 </template>

 <!-- to print -->
 <v-dialog v-model="dialogPrint">
   <div  >
    <v-data-table
     id="table"
     outline
    :headers="headersPrint"
    :search="search"
    :items="customers"   
     class="elevation-1" 
     hide-default-footer
     disable-pagination 
    ></v-data-table>
  </div>
 </v-dialog>
 <!-- uploud file dialog -->
 <v-dialog
   v-model="dialogUpload" 
   max-width="500px"
   transition="dialog-transition"
 >
   <v-card>
     <v-card-title primary-title>
       قم بأختير الملف
     </v-card-title>
     <v-card-text> 
       <v-text-field 
         v-if="uploadType=='file'"
         label="اسم الملف"
         v-model="titleFile"
       ></v-text-field>
       <v-file-input @change="uploadFile($event)"></v-file-input>

     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer> 
       <v-btn dark text color="red" @click="dialogUpload=false">الغاء</v-btn>
       <v-btn text color="success" @click="saveUploadFile()">حفظ</v-btn>
       <v-spacer></v-spacer> 
     </v-card-actions>
   
   </v-card>
 </v-dialog>


  
</v-container>

</template>
<script>
import tools from '@/plugins/tools'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex' 
  export default {
    name:'customersCard',
    data: () => ({
      formData:new FormData,
      titleFile:'',
      api:"", 
      dialog: false,
      dialogDelete: false,
      dialogDetails:false,
      dialogPrint:false,
      dialogUpload:false,
      uploadType:'', 
      search:'', 
      rules: {
          phone: [val => val.length > 11 || `يجب ان يكون رقم الهاتف مكون من 11 رقم على الاقل`], 
          name: [val => (val || '').length > 0 || 'ادخل الاسم '], 
          address: [val => (val || '').length > 0 || 'ادخل العنوان '], 
        }, 
           marital_status: [
                {
                id: 1,
                name: "متزوج"
                },
                {
                id: 2,
                name: "أعزب"
                },
                {
                id: 3,
                name: "أرمل"
                },
                {
                id: 4,
                name: "مطلق"
                }
                ],
      headers: [
        { text: 'الاسم',value: 'name',align:'start',sortable: false,},
        { text: 'العنوان ', value: 'address', sortable: false },
        { text: 'الهاتف', value: 'phone' },
        { text: 'رقم الهوية', value: 'national_id' }, 
        { text: 'الحالة الزوجية', value: 'marital_status.name' }, 
        { text: 'اسم الام', value: 'customer.mother_name' }, 
        { text: 'زمرة الدم', value: 'customer.blood_type' }, 
        { text: 'الشارع', value: 'customer.street' }, 
        { text: 'عدد الاولاد', value: 'customer.children_count' }, 
        { text: 'رقم الدار  ', value: 'customer.house_number' }, 
        { text: 'العمليات', value: 'operation', sortable: false },
      ],
      headersPrint: [
        { text: 'الاسم',value: 'name',sortable: false },
        { text: 'العنوان ', value: 'address',sortable: false },
        { text: 'الهاتف', value: 'phone',sortable: false }, 
        
        { text: 'الحالة الزوجية', value: 'marital_status.name' }, 
        { text: 'اسم الام', value: 'customer.mother_name' }, 
        { text: 'زمرة الدم', value: 'customer.blood_type' }, 
        { text: 'الشارع', value: 'customer.street' }, 
        { text: 'عدد الاولاد', value: 'customer.children_count' }, 
        { text: 'رقم الدار  ', value: 'customer.house_number' }, 
        { text: 'رقم الهوية', value: 'national_id' }, 
        { text: 'النوع', value: 'contractor.contractor_type.name',sortable: false }, 
      ], 
      editedIndex: -1,
      editedItem: {
        name: '',
        address: '',
        phone: '',
        nots: '',
        documents: null, 
        marital_status_id:'',
        house_number:'',
        mother_name:''
      },
      defaultItem: {
        name: '',
        address: '',
        phone: '',
        nots: '',
        documents: null, 
        marital_status_id:''
      },
    }),

    computed: {
      ...mapGetters(['customers']),
      formTitle () {
        return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
      },
      formIsValid () {
        return (
          Boolean(this.editedItem.name ) &&
          Boolean(this.editedItem.address) && 
          Boolean(this.editedItem.phone.length>=11) 
        )
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () { 
      function url(){
        let a= axios.defaults.baseURL.toString()
          return  a.slice(0,-3)+"/uploads/"
      }
      this.api=url();

      this.f_customer() 
    },
    updated() { 
    },  
    

    methods: {
      ...mapActions(['f_customer','deletePersonImage','uploadPersonImage',
                      'uploadPersonDoc','deleteCard','addCard','updateCard','deleteDoc']),

      print() {
         this.dialogPrint=true ;
        setTimeout(function(){ 
          var divToPrint=document.getElementById("table");
           var newWin=window.open("","","width=900,height=700 ");
           newWin.document.write(` <h3 style="text-align: center;">مكتب الجود الرافدين الهندسي</h3> `);
           newWin.document.write(divToPrint.outerHTML);
            const styleSheet = document.createElement("style")
            styleSheet.type = "text/css"
            styleSheet.innerText = `

                  table {
                  direction: rtl;
                  font-family: Arial, Helvetica, sans-serif;
                  border-collapse: collapse;
                  width: 100%;
                }

                table td, table th {
                  text-align: center !important;
                  border: 1px solid #ddd;
                  padding: 5px;
                }
                
                table th {
                  padding-top: 5px;
                  padding-bottom: 5px; 
                }` 
            // table {direction: rtl;} td,th { border: 1px solid #9e9e9e; margin: 0px !important; text-align: center !important;}'
            newWin.document.head.appendChild(styleSheet)
          //  newWin.focus()
            newWin.print();
            newWin.close();
            this.dialogPrint=false ; 

          }, 500);// wait dialog to open
        }, 

        checkPermissions(per){ tools.checkPermissions(per)},


      uploadDoc(){//@click
        this.dialogUpload=true
        this.uploadType='file'
      },
      uploadImage(){//@click
        this.dialogUpload=true
        this.uploadType='image'
      },
      uploadFile(f){ 
        this.formData.append(this.uploadType,f)  
      },
       saveUploadFile(){
        if(this.uploadType=='file'){
          this.formData.append('person_id',this.editedItem.id)
          this.formData.append('title',this.titleFile)
          this.uploadPersonDoc(this.formData)  
          this.titleFile='' 
        }
        if(this.uploadType=='image'){
          this.formData.append('id',this.editedItem.id)
          this.uploadPersonImage(this.formData)   

        }
 
        this.dialogUpload=false;
        this.dialogDetails=false;
       },
        
      editItem (item) {
        this.checkPermissions('card-update')
        this.editedIndex = this.customers.indexOf(item)
        this.editedItem = Object.assign({}, item) 
        this.editedItem.mother_name=item.customer.mother_name

        this.editedItem.marital_status_id=item.marital_status.id
        this.editedItem.blood_type=item.customer.blood_type

        this.editedItem.street=item.customer.street
        this.editedItem.children_count=item.customer.children_count
        this.editedItem.house_number=item.customer.house_number 
        

        this.dialog = true
      },

      deleteItem (item) {
        this.checkPermissions('card-delete') 
        this.editedIndex = this.customers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      details(item){
        this.editedIndex = this.customers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDetails = true 
      },

      deleteItemConfirm () { 
        let card=[]; card.type="customer"
        card.id=this.editedItem.customer.id
        this.deleteCard(card)
        // this.customers.splice(this.editedIndex, 1)
        this.closeDelete()  
      },

      close () {
        this.dialog = false
        this.dialogDetails = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {//save dialog add and edit
     
       
        this.editedItem.person_type_id=1 

        if (this.editedIndex > -1) { 
          //update item 

          let card=[];card.type='customer'
          card.id=this.editedItem.customer.id
          card.data=this.editedItem; 
          this.updateCard(card) 

        } else {
          //add new item 
          this.editedItem.person_type_id=1

          let card=[];card.type='customer' 
          card.data=this.editedItem;
 
 
          this.addCard(card)  
        }
        this.close()
      },

    }, 
  }
</script>
