// import axios from "axios";

import axios from "axios";
import companyIcons from "@/assets/companyIcons";
 
    export default function printing(tag){  
       
        // let image = s
         axios.get("a/setting/get")
        .then((res) => {
            let setings=res.data.setting  
            setTimeout(function(){  
            
                var newWin=window.open("","","width=900,height=700 ");
                let time=new Date().toLocaleTimeString(undefined,{ hour12: false });
 
                let date=new Date().toLocaleDateString();
                let arr= date.split("/")
               date=arr[1] + '-' + arr[0] + '-' + arr[2] 

                newWin.document.write(` 
                <html lang="ar">
                   <div>   
                    <div style="display: flex; justify-content: space-between;">
                     <img>
                     <img  height="45"  style="text-align:right;"  src="data:image/png;base64, ${companyIcons.data().imageBase}" />
                    </div>   
                   <img  height="90" width="90" style=" display: block;  margin-left: auto; margin-right: auto; margin-top:-40px "  src="${setings.company_photo}" alt=""> 
                   <h3 style="text-align: center;">${setings.company_name}</h3>
                   <div style="text-align: center; margin: 10px 0;">
                      ${setings.second_party}
                    </div>
                     <div style="display: flex; justify-content: space-between; margin: 2px"  >
                        <div>
                        <h5 style="text-align: right;margin: 2px" ">العنوان :${setings.company_address} </h5> 
                            <h5 lang='en' style="text-align: right;margin: 2px" ">  تاريخ الطباعة :${date} </h5> 
                            <h5 style="text-align: right;margin: 2px" "> <span> وقت الطباعة: </span>  <span> ${time} </span>   </h5> 
                        </div>
                        
                        <div>
                        <h5 style="text-align: right;margin: 2px" "> ${setings.company_phone} :رقم الهاتف</h5> 
                        <h5 style="text-align: right;margin: 2px" ">بأدارة :${setings.company_boss_name}</h5> 
                        <h5 style="text-align: right;margin: 2px" "> ${setings.company_email} :البريد الالكتروني</h5> 
                        </div>
                    </div> 
                        
                    <hr>  
                 </html>
                `
                                        
                );
    
    
                for (let index = 0; index < tag.length; index++) {
                    var divToPrint=document.getElementById(tag[index].text); 
                    if(divToPrint)
                    newWin.document.write(divToPrint.outerHTML); 
                }
                 
                const styleSheet = document.createElement("style")
                styleSheet.type = "text/css"
                styleSheet.innerText = `
                
                table {
                    direction: rtl;
                    border-collapse: collapse;
                    width: 100%;
                }
                
                table td, table th {
                    text-align: center !important;
                    border: 1px solid #ddd;
                    padding: 5px;
                } 
                .p_center{
                    text-align:center
                }
                .p_right{
                    text-align:right
                }
                .p_left{
                    text-align:left
                }
                .p_box{
                    border:1px solid black;
                    margin:5px;
                    padding:3px
                }
                
                table th {
                    padding-top: 5px;
                    padding-bottom: 5px; 
                }
                .bg-gray{
                    background-color:#E0E5EB;
                }
                body{
                    border: solid 1px gray;
                    padding: 8px;
                    
                }
                .p_dnon{
                    display: none;
                }
                
                ` 
    
    
                newWin.document.head.appendChild(styleSheet)
    
                setTimeout(function(){
                     newWin.print();
                     newWin.close(); 
                     },1000)
               
                
                // 
                
            }, 100);// wait dialog to open
        })
        .catch((err) => { 
            console.log(err  );
        });
       
    }
    
