<template>
  <v-container fluid> 
      <template>
<!-- main table -->
  <v-data-table   
    :headers="headers"
    :search="search"
    :items="employees"   
    
     class="elevation-0 my_btn bg-main"
    :footer-props="{
          'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
          }"
  >
    <template v-slot:top>
      <v-toolbar
        flat class="bg-main"
      >
      <!-- header -->
        <v-toolbar-title >الموظفين</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- search -->
         <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details 
        ></v-text-field>
         <v-spacer></v-spacer>
        <!-- add and delete dialog -->
        <v-dialog 
          v-model="dialog"
          max-width="700px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary" 
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="checkPermissions('card-add')"
            >أضافة<v-icon>mdi-plus</v-icon>  
            </v-btn> 
            <!-- print btn
            <v-btn 
              class="mb-2 mx-2" 
              @click="print()"
            >  <v-icon >mdi-printer-outline</v-icon>
            </v-btn> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <!-- dialog add and edit  -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="rules.name"
                      required
                      label="الاسم"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.address"
                      :rules="rules.address"
                      required
                      label="العنوان"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.phone"
                      :rules="rules.phone" 
                      required
                      label="رقم الهاتف"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.national_id" 
                      label="رقم الهوية"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                  
                    <v-select 
                      v-model="editedItem.employee_type_change" 
                      :items="employeeTypes" 
                      :rules="rules.employee"
                      required
                      item-text="name"
                      item-value="id"
                      label="نوع الموظف" 
                    ></v-select>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.salary"
                      :rules="rules.salary" 
                       type="number"
                      required
                      label="الراتب"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.birth_date" 
                      type="date"
                      label="تاريخ الميلاد"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.job"
                      label="الوظيفة"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.section" 
                      label="القسم"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.division" 
                      label="الشعبة"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.graduation_year" 
                       
                      label="سنة التخرج"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.employee.work_hours" 
                      label="ساعات العمل"
                    ></v-text-field>
                  </v-col> 
                  <v-col
                    cols="12" 
                  >
                    <v-text-field
                      v-model="editedItem.notes" 
                      label="ملاحضات"
                    ></v-text-field>
                  </v-col> 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close" > إلغاء </v-btn>
              <v-btn color="green"    :disabled="!formIsValid"  type="submit"  @click="save" > حفظ </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog  v-model="dialogDelete" max-width="500px">
          <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
              <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- details dialog -->
        <v-dialog  v-model="dialogDetails" max-width="500px">
          <v-card class="text-center">
            <v-card-title text  >بيانات الموظف</v-card-title>
            <v-card-text> 

              <img  class=" my-3" width="100px" height="100px"  :src="api+editedItem.image" alt="لايوجد صورة">
              <br> 
              <v-btn icon @click="deletePersonImage(editedItem.id);" color="error"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn icon @click="uploadImage()" color="success"><v-icon>mdi-upload</v-icon></v-btn>
               
              <hr>
              <div class="text-start">
                  <div  class=" mt-3" >الاسم:  <b>{{editedItem.name}}</b></div>
                  <div>العنوان: <b>{{editedItem.address}}</b></div>
                  <div>الهاتف: <b>{{editedItem.phone}}</b></div>
                  <div>الملاحظات: <b>{{editedItem.notes}}</b></div> 
                  <div>الراتب: <b>{{editedItem.employee.salary}}</b></div> 
                  <div>النوع: <b>{{editedItem.employee.employee_type.name}}</b></div> 
                  <div>ساعات العمل: <b>{{editedItem.employee.work_hours}}</b></div> 
                  <!-- <div> الوظيفة: <b>{{editedItem.employee.jop}}</b></div>  -->
                  <div> الوظيفة: <b>{{editedItem.employee.job}}</b></div> 
                  <div> القسم: <b>{{editedItem.employee.section}}</b></div> 
                  <div> الشعبة: <b>{{editedItem.employee.division}}</b></div> 
                  <div> سنة التخرج: <b>{{editedItem.employee.graduation_year}}</b></div> 
                  <div>تاريخ الميلاد: <b>{{editedItem.employee.birth_date}}</b></div>  
                  <div> رقم الهوية: <b>{{editedItem.national_id}}</b></div>  
              </div>
             
              <hr>
              <div  class=" my-3" >المستندات:<v-btn x-small dark color="green"   @click="uploadDoc()">اضافة<v-icon>mdi-plus</v-icon></v-btn> 
              
               <div class="" v-for="item,i in editedItem.documents" :key="i">
               <b> {{i+1}} - {{item.title}}  <v-btn small icon color="red" @click="deleteDoc(item.id)" ><v-icon >mdi-delete</v-icon></v-btn> 
                                            <v-btn small icon color="success" :href="api+item.file" ><v-icon  color="success">mdi-download</v-icon></v-btn> 
               </b> 
               </div>
              </div>
              <!-- <div>النوع: <b>{{editedItem.employee_type.name}}</b></div> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer> 
              <v-btn color="red darken-1" text @click="close">إغلاق</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!-- actions of table -->
    <template  v-slot:[`item.operation`]="{item}" >
      <v-icon color="" class="mr-2" @click="editItem(item)" > mdi-pencil </v-icon>
      <v-icon color="red" @click="deleteItem(item)" > mdi-delete </v-icon>
      <v-icon color="blue"  @click="details(item)" > mdi-format-list-text </v-icon> 
    </template>
    <template v-slot:no-data>
      <v-card
      loading
      ><v-btn > انتظر..... </v-btn>
      </v-card>
    </template>
  </v-data-table>
 </template>

 <!-- to print -->
 <!-- <v-dialog v-model="dialogPrint">
   <div  >
    <v-data-table
     id="table"
     outline
    :headers="headersPrint"
    :search="search"
    :items="employees"   
     class="elevation-1" 
     hide-default-footer
     disable-pagination 
    ></v-data-table>
  </div>
 </v-dialog> -->
 <!-- uploud file dialog -->
 <v-dialog
   v-model="dialogUpload" 
   max-width="500px"
   transition="dialog-transition"
 >
   <v-card>
     <v-card-title primary-title>
       قم بأختير الملف
     </v-card-title>
     <v-card-text> 
       <v-text-field 
         v-if="uploadType=='file'"
         label="اسم الملف"
         v-model="titleFile"
       ></v-text-field>
       <v-file-input @change="uploadFile($event)"></v-file-input>

     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer> 
       <v-btn dark text color="red" @click="dialogUpload=false">الغاء</v-btn>
       <v-btn text color="success" @click="saveUploadFile()">حفظ</v-btn>
       <v-spacer></v-spacer> 
     </v-card-actions>
   
   </v-card>
 </v-dialog>


  
</v-container>

</template>
<script>
import tools from '@/plugins/tools'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex' 
  export default {
    name:'employeesCard',
    data: () => ({
      formData:new FormData,
      titleFile:'',
      api:"", 
      dialog: false,
      dialogDelete: false,
      dialogDetails:false,
      dialogPrint:false,
      dialogUpload:false,
      uploadType:'', 
      search:'', 
      rules: {
          phone: [val => val.length > 11 || `يجب ان يكون رقم الهاتف مكون من 11 رقم على الاقل`],
          employee: [val => (val || '')!= '' || 'اختر نوع الموظف'],
          name: [val => (val || '').length > 0 || 'ادخل الاسم '], 
          address: [val => (val || '').length > 0 || 'ادخل العنوان '], 
          salary: [val => (val || '').length > 0 || 'ادخل الراتب '], 
          // graduation_year:[val => (val || '') > 1900 || 'يجب ان يكون سنة بعد 1900'], 
        },
      employeeType:[{id:1,name:'المجهزين'},{id:2,name:'المقاولين الثانويين'}],
      headers: [
        { text: 'الاسم',value: 'name',align:'start',sortable: false,},
        { text: 'الهاتف', value: 'phone' },
        { text: 'النوع', value: 'employee.employee_type.name' },
        { text: 'الراتب', value: 'employee.salary' },
        { text: 'ساعات العمل', value: 'employee.work_hours' },
        { text: 'العمليات', value: 'operation', sortable: false },
      ],
      headersPrint: [
        { text: 'الاسم',value: 'name',sortable: false },
        { text: 'العنوان ', value: 'address',sortable: false },
        { text: 'الهاتف', value: 'phone',sortable: false },
        { text: 'النوع', value: 'employee.employee_type.name',sortable: false  }, 
        { text: 'الراتب', value: 'employee.salary',sortable: false  },
        { text: 'ساعات العمل', value: 'employee.work_hours',sortable: false  },
       
      ],  
      editedIndex: -1,
      editedItem: {
        name: '',
        address: '',
        phone: '',
        nots: '',
        documents: null,
        employee:{
          salary:"",
          birth_date:"",
          employee_type:""
          },
        employee_type_id: '', 
      },
      defaultItem: {
        name: '',
        address: '',
        phone: '',
        nots: '',
        documents: null,
        employee:{
          salary:"",
          birth_date:"",
          employee_type:""
          },
        employee_type_id: '', 
      },
    }),

    computed: {
      ...mapGetters(['employees','employeeTypes']),
      formTitle () {
        return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
      },
      formIsValid () {
        return (
          Boolean(this.editedItem.name ) &&
          Boolean(this.editedItem.address) &&
          Boolean(this.editedItem.employee_type_change)  &&
          Boolean(this.editedItem.phone.length>=11) &&
          Boolean(this.editedItem.employee.salary)  
        )
        }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted() {
       this.f_employee()
      this.f_employeeTypes()
    }, 
    updated() {
      function url(){
        let a= axios.defaults.baseURL.toString()
          return  a.slice(0,-3)+"/uploads/"
      }
      this.api=url(); 
    },  
    

    methods: {
      ...mapActions(['f_employee','deletePersonImage','uploadPersonImage','f_employeeTypes',
                      'uploadPersonDoc','deleteCard','addCard','updateCard','deleteDoc']),
 
      
        checkPermissions(per){ tools.checkPermissions(per) },

      uploadDoc(){//@click
        this.dialogUpload=true
        this.uploadType='file'
      },
      uploadImage(){//@click
        this.dialogUpload=true
        this.uploadType='image'
      },
      uploadFile(f){ 
        this.formData.append(this.uploadType,f) 
      },
       saveUploadFile(){
        if(this.uploadType=='file'){
          this.formData.append('person_id',this.editedItem.id)
          this.formData.append('title',this.titleFile)
          this.uploadPersonDoc(this.formData)  
          this.titleFile='' 
        }
        if(this.uploadType=='image'){
          this.formData.append('id',this.editedItem.id)
          this.uploadPersonImage(this.formData)   

        }
 
        this.dialogUpload=false;
        this.dialogDetails=false;
       },
        
      editItem (item) {
        this.checkPermissions('card-update')

        this.editedIndex = this.employees.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.employee_type_change= this.editedItem.employee.employee_type.id
        this.dialog = true
      },

      deleteItem (item) {
        this.checkPermissions('card-delete')

        this.editedIndex = this.employees.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      details(item){
        this.editedIndex = this.employees.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDetails = true 
      },

      deleteItemConfirm () {
        let card=[]; card.type="employee"
        card.id=this.employees[this.editedIndex].employee.id
        this.deleteCard(card)
        // this.employees.splice(this.editedIndex, 1)
        this.closeDelete()  
      },

      close () {
        this.dialog = false
        this.dialogDetails = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {//save dialog add and edit
          let params={}
          params.address= this.editedItem.address
          params.phone= this.editedItem.phone
          params.name= this.editedItem.name 
          params.employee_type_id= this.editedItem.employee_type_change
          params.national_id= this.editedItem.national_id
          params.salary= this.editedItem.employee.salary
          params.graduation_year= this.editedItem.employee.graduation_year
          params.job= this.editedItem.employee.job
          params.section= this.editedItem.employee.section
          params.work_hours= this.editedItem.employee.work_hours

          params.birth_date= this.editedItem.employee.birth_date
          params.division=this.editedItem.employee.division  
          params.notes=this.editedItem.notes
          params.person_type_id=3 

        if (this.editedIndex > -1) { 
          //update item   
          let card=[];card.type='employee'
          card.id=this.employees[this.editedIndex].employee.id
          card.data=params;
 
          this.updateCard(card) 

        } else {
          //add new item  
          let card=[];card.type='employee' 
          card.data=params;


 
          this.addCard(card)   
        }
        this.close()
      },

    }, 
  }
</script>
