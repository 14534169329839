<template>
            <v-container >
            <v-row >
                <v-col   
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field 
                    reverse
                    outlined
                    v-model="customer.name"
                    required
                    label="الاسم"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.address" 
                    required
                    label="العنوان"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.phone" 
                    :rules="[val => val.length > 10 || `يجب ان يكون رقم الهاتف مكون من 11 رقم على الاقل`]"
                    required
                    label="رقم الهاتف"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    required
                    outlined
                    v-model="customer.national_id"
                    label="رقم الهوية"
                ></v-text-field>
                </v-col>
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.mother_name"
                    required
                    label="اسم الام"
                ></v-text-field>
                </v-col>
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.house_numper"
                    required
                    label="رقم الدار"
                ></v-text-field>
                </v-col>
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.street"
                    required
                    label="الشارع"
                ></v-text-field>
                </v-col>
                 <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete
                    outlined
                    auto-select-first
                    reverse
                    v-model="customer.marital_status_id"
                    :items="martial_status" 
                    required
                    item-text="name"
                    item-value="id"
                    label="الحاله الزوجية"
                ></v-autocomplete>
                </v-col>
                    
                 <v-col
                cols="12"
                sm="6"
                md="4"
                >
                <v-text-field
                    reverse
                    outlined
                    v-model="customer.children_count"
                    type="number"
                    required
                    label="عدد الاولاد"
                ></v-text-field>
                </v-col>
                
            </v-row>
             <v-btn @click="next()"  :disabled="!formIsValid"  class="text-end" color="success">التالي</v-btn>
            </v-container>
</template>
<script> 
import axios from 'axios'

export default {
    name:'customerInfo',
    data(){
        return{
            customer:{
                name:'',
                phone:'',
                address:'',
                national_id:'',
                mother_name:'',
                street:'',
                children_count:'',
                house_numper:'',
                martial_status_id:'',
                person_type_id:1
            },
            martial_status: [
                {
                id: '1',
                name: "متزوج"
                },
                {
                id: '2',
                name: "أعزب"
                },
                {
                id: '3',
                name: "أرمل"
                },
                {
                id: '4',
                name: "مطلق"
                }
                ]
            
        }
    },
    computed:{
        formIsValid(){
             return (
                Boolean(this.customer.name )  &&
                Boolean(this.customer.phone.length > 10)  &&
                Boolean(this.customer.address )  &&
                Boolean(this.customer.marital_status_id )  
        )
        },
    },
    methods:{
        next(){
            axios.post('a/customer/add',this.customer)
            .then(res => { 
                this.$emit('next',res.data)
            })
            .catch(err => {
                console.error(err); 
            })
            
        }
    }
}
</script>