

import router from "@/router"
import Vue from 'vue';

//get permission of users


const checkPermissions = (name) => { 

    let my_permissions= localStorage.getItem('permissions')
    my_permissions = JSON.parse(my_permissions)
 
    if (!my_permissions.find((element) => element.name == name))
            router.push('/403') 
    }
  const  oprationSuccess=(message)=>{
        console.log(message)
        Vue.toasted.show(message,{type:'success',theme: "outline", 
            position: "bottom-right", 
            duration : 2000}); 
    }
    const   oprationFailed=(message)=>{
        Vue.toasted.show(message ,{type:'error',theme: "outline", 
            position: "bottom-right", 
            duration : 5000}); 
    }
  


export default{
    checkPermissions, 
    oprationSuccess,
    oprationFailed
}