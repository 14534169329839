<template>
    
<v-app   id="fullscreen" class="body"  dir="rtl" >

   <v-app-bar
      color="main" 
      class="hiden" 
      app  
    >
      <v-app-bar-nav-icon color="blue"   @click="drawer=!drawer" ></v-app-bar-nav-icon>
 

      <v-spacer></v-spacer>
      <v-menu
      
        left
        bottom
      > 
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          class="my_btn"
            color="blue" 
            small
            icon
            v-bind="attrs"
            v-on="on"
            @click="f_my_safes"
          >
            <v-icon>mdi-safe</v-icon>
          </v-btn>
        </template> 
        <v-list>  
                <v-list-item v-for="safe in my_safes"  :key="safe.id">
                    <v-list-item-title >
                       {{ safe.name }} : 
                       {{ safe.balance }}  
                    </v-list-item-title>
                </v-list-item> 
        </v-list>
        
        </v-menu>
      

      <v-btn small to="/mainSetting" class="my_btn mx-3" color="blue" icon>
        <v-icon >mdi-cog</v-icon>
      </v-btn> 
        <v-menu
        left
        bottom
      >
      
        <template v-slot:activator="{ on, attrs }">
        <v-badge
        :content="notificationsCount"
        :value="notificationsCount"
        color="green" 
        overlap
        > 
          <v-btn class="my_btn " color="blue" small
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon  >mdi-bell</v-icon>
          </v-btn>
      </v-badge>
        </template>

      

        <v-list class="notifications"> 
          <v-list-item @click="read(noti.id)"  v-for="noti in notifications" :key="noti.id">
          <v-card
           class="my-2" 
            elevation="0"
            :disabled="noti.is_watched"
          >
           <div>{{noti.title}}</div> 
           <div>{{noti.created_at[0]}}</div>
          </v-card>  
          </v-list-item>
        </v-list>
        
        </v-menu>



      <v-menu
        left
        bottom
      >
      
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="blue" 
            small
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template> 
        <v-list>  
                <v-list-item to="/changePassword" @click="() => {}">
                    <v-list-item-title>
                        <v-icon color="gray">mdi-lock-outline</v-icon> تغيير كلمة السر
                    </v-list-item-title>
                </v-list-item>
                <hr>

                <v-list-item @click="logout()">
                    <v-list-item-title color="red" class="text--red">
                        <v-icon color="red">mdi-logout</v-icon> تسجيل الخروج
                    </v-list-item-title>
                </v-list-item>
        </v-list>
        
        </v-menu>
      <div  class="mx-2 text_black" >{{person.name}}</div>
        <v-avatar 
          size="40" 
          color="blue"
        >
          <v-icon  dark>
            mdi-account-circle
          </v-icon>    
        </v-avatar> 

    </v-app-bar>
  <v-navigation-drawer class="bg-main hiden" id="hidden" v-model="drawer" right color=""  app    >

    <!-- menu -->  
    <div class="text-center my-1">
      <img  height="80" width="200" src="../../assets/logo.png" alt="">
    </div>
    <hr>
    <v-list>
      <v-list-item-group
        v-model="model"  
        color="blue"
      >
        <v-list-item 
          v-for="(item, i) in mainMenu"
          :key="i" 
          :to="item.path" 
        >
          <v-list-item-icon>
            <v-icon class="mx-1" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title  v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list> 
  </v-navigation-drawer>
  <v-main>  
    <router-view></router-view> 
  </v-main> 
  
</v-app>
</template>
<script>
import axios from 'axios'; 
import { mapActions, mapGetters } from 'vuex'
export default {
    name:"homeView",
    data(){
      return{ 
        api:"",
        isfull:false,
        drawer: true, 
        count:0,
            mainMenu: [
                {
                id:0, 
                icon: 'mdi-home',
                text: ' الرئيسية',
                path:'/'
                },
                {
                id:1, 
                icon: 'mdi-account-hard-hat-outline',
                text: 'الموقف اليومي',
                path:'/dailyPosition'
                },
                { 
                id:2,
                icon: 'mdi-office-building',
                text: 'المشاريع',
                path:'/projects' 
                }, 
                {
                id:3,
                icon: 'mdi-card-bulleted-outline',
                text: 'البطاقات',
                path:"/contractorsCard"
                },   {
                  id:8,
                icon: 'mdi-plus-thick',
                text: 'أضافة فقره  ',
                path: '/expenceInfo'
              },
                {
                id:4,
                icon: 'mdi-account-cash-outline',
                text: 'الحسابات',
                path:'/showAccount'
                },
             
              {
              id:133,
              icon: 'mdi-account-cash-outline',
              text: 'الميزانية',
              path:'/accounts'
              },
              {
                id:5,
                icon: 'mdi-chart-areaspline',
                text: 'التقارير',
                path: '/mainReport'
              },
                 {
                id:6,
                icon: 'mdi-office-building-cog',
                text: 'المشاريع التخمينية',
                path: '/guessProjects'
              },
              
              {
                id:7,
                icon: 'mdi-swap-horizontal',
                text: 'تحويلات القاصات ',
                path: '/transactionSafes'
                },
                 {
                id:8,
                icon: 'mdi-office-building',
                text: 'أنشاء مشروع جديد ',
                path: '/newProject'
                },
               
            ],
            model: 0,
      }
    },
    computed:mapGetters(['person' ,'notifications','notificationsCount','my_safes']),
    methods:{
     
    ...mapActions(['setUserFromLocal','logout','f_notifications','read','f_my_safes']),
    fullscreen(){
      if (this.isfull) {
           document.exitFullscreen();
           this.isfull=false
          }
      else{
        var element=document.getElementById('fullscreen')
        element.requestFullscreen();
        this.isfull=true
      }
       
      },
    },
    created(){
      this.f_notifications()
      function url(){
        let a= axios.defaults.baseURL.toString()
          return  a.slice(0,-3)+"uploads/"
      }
      this.api=url();
       this.setUserFromLocal()
    },  
    updated(){
      if(this.count==4){
        this.f_notifications()
        this.count=0
      }
      this.count++
      
    }
}
</script> 
<style >
.body{
  font-family: 'Almarai', sans-serif; 
  background-color: #E0E5EB !important;
}
.bg-main{
  
  background-color: #E0E5EB !important;
}
.my_btn{
  background-color: #E0E5EB;
  filter: drop-shadow(1px 1px 6px #0000003a) drop-shadow(-3px -3px 4px #ffffff);
}
.text_black{
 color: #4C657F;
}
.notifications{
  height: 400px;
  width: 400px;
  background-color: #fff;
  overflow: scroll;
  cursor: pointer;
}
@media print {
   .hiden{
     display: none;
   }
     
  }

</style>