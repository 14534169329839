import axios from "axios"
const state = {
    companyReport: [],
    projectExpenscesTypeReport:[],
    projectConstructionsReport:[]
}

const getters = {
    companyReport: (state) => state.companyReport,
    companyExpensesReport: (state) => state.companyExpensesReport,
    projectExpenscesTypeReport: (state) => state.projectExpenscesTypeReport,
    projectConstructionsReport: (state) => state.projectConstructionsReport,

}

const mutations = {
    setCompanyReport: (state, companyReport) => state.companyReport = companyReport,
    setCompanyExpensesReport: (state, companyExpensesReport) => state.companyExpensesReport = companyExpensesReport,
    setProjectExpenscesTypeReport: (state, projectExpenscesTypeReport) => state.projectExpenscesTypeReport = projectExpenscesTypeReport,
    setProjectConstructionsReport: (state, projectConstructionsReport) => state.projectConstructionsReport = projectConstructionsReport,

}
const actions = {
    f_companyReport({commit},years){
        axios.get('a/report/'+years)
        .then(res => {
            commit('setCompanyReport',res.data)
            
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_projectReport({commit},id){
        axios.get('a/report/project_report/'+id)
        .then(res => { 
             
            //setProjectExpenscesTypeReport
            let r = res.data.expense_type_report
            let report = {
                lables: [],
                values: []
            }
            for (let i = 0; i < r.length; i++) {
                report.lables[i] = r[i].name
                report.values[i] = r[i].amount 
            }
            // console.log('f_report:', report)
            commit('setProjectExpenscesTypeReport',report)
           //setProjectConstructionsReport
           r = res.data.construction_category_report
           report = {
               lables: [],  
               values: []
           }
           for (let i = 0; i < r.length; i++) {
            report.lables[i] = r[i].name
            report.values[i] = r[i].amount 
           }
        //    console.log('f_report:', report)
           commit('setProjectConstructionsReport',report)



        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_companyExpensesReport({
        commit
    }) {
        axios.get('a/report/company_report')
            .then(res => {
                
                let r = res.data.report
                let report = {
                    lables: [],
                    values: []
                }
                for (let i = 0; i < r.length; i++) {
                    report.lables[i] = r[i].month_name
                    report.values[i] = r[i].amount 
                }
                // console.log('f_report:', report)
                // commit('setCompanyExpensesReport', report)
            })
            .catch(err => {
                console.error(err);
            })

            axios.get('a/report/profit_report')
            .then(res => {
                
                let r = res.data.profit
                let report = {
                    lables: [],
                    values: []
                }
                for (let i = 0; i < r.length; i++) {
                    report.lables[i] = r[i].month_year
                    report.values[i] = r[i].profit_month 
                }
                // console.log('f_report:', report)
                commit('setCompanyExpensesReport', report)
            })
            .catch(err => {
                console.error(err);
            })
    }
}

export default {
    getters,
    state,
    actions,
    mutations
}