<template>
    <v-container fluid>
         <v-row>
            <v-col cols="12"  >
              coming soon 
            </v-col>
         </v-row>
    </v-container>
</template>
<script> 
export default {
    props:['id'], 
    name:'projectImage'
}
</script>