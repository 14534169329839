   
<template> 
  <v-container fluid>
    
    <v-tabs   
    justify-center
      class="my-2"
      color="blue"
      dir="ltr"
      height="70"
      v-model="tab"  
      background-color="main"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-spacer></v-spacer>
      <v-tab  
        v-for="item in items"
        :key="item.tab" 
        @click="open(item)"
      >
        {{ item.tab }} 
      <v-icon> {{item.icon}} </v-icon>

      </v-tab>
      
      <v-spacer></v-spacer>
    </v-tabs> 
    <v-container fluid>
       <project-export v-if="items[0].opened" :id="id"/>
       <!-- <project-import    v-if="items[1].opened" :id="id"/>  -->
    <project-info   v-if="items[1].opened" :id="id"/>
    <!-- <project-design v-if="items[2].opened" :id="id"/> -->
    <project-image  v-if="items[2].opened" :id="id"/>
    <project-report v-if="items[3].opened" :id="id"/>
    </v-container>
   
  </v-container> 
</template>
<script>  
import ProjectExport from '@/components/project/projectExport.vue'
// import projectDesign from '@/components/project/projectDesign.vue'
import ProjectInfo   from '@/components/project/projectInfo.vue'
import ProjectImage  from '@/components/project/projectImage.vue' 
import ProjectReport from '@/components/project/projectReport.vue'
// import ProjectImport from '@/components/project/projectImport.vue'

  // projectDesign, 
  export default {
  components: { ProjectExport,  ProjectInfo, ProjectImage, ProjectReport,   }, 
    data () {
      return {
        tab: null,
        id:null,
        items: [
          { tab: 'حسابات المشروع', path:'projectExport',icon:'mdi-cash' , opened:true  },
          // { tab: 'الدفعات المستلمة', path: 'projectImport' ,icon:'mdi-arrow-down' ,  opened:false   },
          { tab: 'معلومات', path: 'projectInfo'  ,icon:'mdi-information-outline' , opened:false  },
          // { tab: 'التصاميم', path: 'projectDesign',icon:'mdi-pencil-ruler' , opened:false   },
          { tab: 'مراحل الانجاز', path: 'projectImage',icon:'mdi-step-forward' , opened:false   },  
          { tab: 'التقرير', path: 'projectReport',icon:'mdi-chart-areaspline' , opened:false   },  
        ],
      }
    },
    methods:{
      open(item){
        this.items.forEach((item)=>(item.opened=false))
        item.opened=true
      }
    },
    created(){
      this.id=this.$route.params.id;
    }
  }
</script>
