<template>
    <v-container fluid>
        <!-- <v-row   class="text-center mt-3"> -->
            <!-- <v-col class="my-5" cols="12" sm="6" md="4">
              <v-btn rounded dark to="/employeeInvoice" class="mx-3  py-5 my_btn elevation-0 bg-main black--text"><h3><v-icon>mdi-file-document-outline</v-icon>  سلف الموظفين  </h3></v-btn>
           </v-col> -->
           <!-- <v-col class="my-5"  cols="12" sm="6" md="4">
              <v-btn rounded  to="/officeAccounts"   class="mx-3 py-5 my_btn elevation-0 bg-main blue--text"><h3><v-icon>mdi-cash</v-icon> حسابات المكتب </h3></v-btn>
           </v-col> --> 
           <!-- <v-col class="my-5 hiden"  cols="12" sm="6" md="4">
              <v-btn rounded  to="/expenceInfo"   class="mx-3 py-5 my_btn elevation-0 bg-main blue--text"><h3><v-icon>mdi-plus-thick</v-icon> أضافة فقرة </h3></v-btn>
           </v-col>
            <v-col class="my-5 hiden"  cols="12" sm="6" md="4">
              <v-btn rounded   to="/showAccount"   class="mx-3  py-5 my_btn elevation-0 bg-main green--text"><h3><v-icon>mdi-list-status</v-icon> كشف حساب </h3></v-btn>
           </v-col> 
           
        </v-row> 
        <hr> -->
        <v-dialog v-model="cashdate" max-width="500px">
        <v-card>
            <!-- delete dialog -->
            <v-card-title text class="text-h6">حدد تاريخ النقد </v-card-title>
            <v-card-text> 
                <v-text-field type="date" outlined v-model="date" required label="الفقرة" reverse>  </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="f_final_cash(); cashdate=false">نقد اليوم</v-btn>
                <v-btn color="green darken-1" text @click="f_final_cash(date) ; cashdate=false">موافق</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
   
        <h2 class="text-center my-5">الميزانية</h2>
        <v-row class="text-center  ">  
          <v-col>
            <table id="customers">
                <tr>
                    <th>الاصول</th>
                    <th>المبلغ</th>
                </tr>  
                <tr > 
                  <td> <h3>المتبقي من سلف الموظفين   </h3> </td>  
                  <td v-if="budget.assets"> <h3>{{ budget.assets.sum_rest_emp_self }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>جاري الشركاء   </h3> </td>  
                  <td  v-if="budget.assets"> <h3>{{ budget.assets.sum_partner_total }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>المبالغ المستحقة على العملاء    </h3> </td>  
                  <td v-if="budget.assets"> <h3>{{ budget.assets.sum_project_box_negative }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>دیون المشاریع القدیمه    </h3> </td>  
                  <td v-if="budget.assets"> <h3>{{ budget.assets.sum_old_projects_rest }}</h3>  </td> 
              </tr> 
              <tr > 
                  <td> <h3>النقد النهائي    </h3> </td>  
                  <td v-if="budget.assets"> <h3>{{ budget.assets.final_cash }}</h3>  </td> 
              </tr> 
              <tr > 
                  <td> <h3>   --  </h3> </td>  
                  <td>   </td> 
              </tr> 
              <!-- <tr > 
                  <td> <h3>   --  </h3> </td>  
                  <td>   </td> 
              </tr>  -->

              <tr > 
                  <td> <h2>مجموع الاصول    </h2> </td>  
                  <td v-if="budget.assets"> <h2>{{ budget.assets.sum_assets }}</h2>  </td> 
              </tr>
               
            </table>  
            </v-col>
            <v-col>

              <table id="customers">
                <tr>
                    <th>الالتزامات</th>
                    <th>المبلغ</th>
                </tr> 
                <tr > 
                    <td> <h3>المتبقي لاصحاب المشاريع  </h3> </td>  
                    <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_project_box_positive }}</h3>  </td> 
                </tr>
                <!-- <tr > 
                    <td> <h3> ديون المجهزين القديم   </h3> </td>  
                    <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_old_supplier }}</h3>  </td> 
                </tr> -->
                <tr > 
                  <td> <h3>ديوين المجهزين   </h3> </td>  
                  <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_rest_debit_suppliers }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>ديوين المقاولین   </h3> </td>  
                  <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_rest_debit_contractors }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>ديون خارجية   </h3> </td>  
                  <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_external_debts }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>مجموع الارباح    </h3> </td>  
                  <td v-if="budget.obligations"> <h3>{{ budget.obligations.profit_company }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h3>راس المال</h3> </td>  
                  <td v-if="budget.obligations"> <h3>{{ budget.obligations.sum_capital_amounts }}</h3>  </td> 
              </tr>
              <tr > 
                  <td> <h2>مجموع  الالتزامات    </h2> </td>  
                  <td v-if="budget.obligations"> <h2>{{ budget.obligations.sum_obligations }}</h2>  </td> 
              </tr>
               
            </table>  
        </v-col>
    </v-row>
    <h2 class="text-center my-4  " v-if="budget.obligations"> الفرق بين الاصول و الالتزامات :{{ budget.asset_diff }}</h2> 
        <v-row class="my-4">
          <v-col>
            <h3 class="text-center mb-1">تفاصيل النقد النهائي</h3>
              <table id="customers">
            <tr>
                    <th>الاسم</th>
                    <th>المبلغ</th>
                </tr> 
                    <tr >
                    <td> المبلغ المدور للنقد النهائي </td>
                    <td> {{ final_cash.final_cash_revolved_balance }} </td>
                </tr>
                <tr >
                    <td> وارد المشاریع  </td>
                    <td> {{final_cash.sum_import_project_period }} </td>
                </tr>
                <tr >
                    <td> وارد غیر المشاریع  </td>
                    <td> {{ final_cash.sum_import_company_period }} </td>
                </tr>
                <tr >
                    <td> المبالغ المستلمه من الموظقين  </td>
                    <td> {{ final_cash.sum_import_employee_self_period }} </td>
                </tr>
                <tr >
                    <td> مجموع كل المبالغ المستلمة    </td>
                    <td> {{ final_cash.sum_all_imports }} </td>
                </tr>
                <tr >
                    <td> المبالغ المدفوعه للموظفين  </td>
                    <td> {{ final_cash.sum_export_employee_self_period }} </td>
                </tr>
                <tr >
                    <td> مبلغ تعزيز رأس المال  </td>
                    <td> {{ final_cash.sum_capital_supply_period }} </td>
                </tr>
                <tr >
                    <td> المبالغ المصروفه للمشاريع  </td>
                    <td> {{ final_cash.sum_export_projects_period }} </td>
                </tr>
                <tr >
                    <td> مصروفات الشركة  </td>
                    <td> {{ final_cash.sum_export_company_period }} </td>
                </tr> 
                <tr >
                    <td> المقبوضة من دیون المشاریع القدیمة  </td>
                    <td> {{ final_cash.sum_import_old_project_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع تسدید المجهزين و المقاولین القديمة  </td>
                    <td> {{ final_cash.sum_pay_old_suppliers_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع تسدید الدیون الخارجیة  </td>
                    <td> {{ final_cash.sum_pay_external_debit_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع المدفوع لجاري الشركاء  </td>
                    <td> {{ final_cash.sum_old_supplier_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع مصروفات المجهزين  </td>
                    <td> {{ final_cash.sum_export_projects_suppliers_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع مصروفات المقاولین  </td>
                    <td> {{ final_cash.sum_export_projects_contractors_period }} </td>
                </tr> 
                <tr >
                    <td> مجموع كل المصروف  </td>
                    <td> {{ final_cash.sum_all_exports }} </td>
                </tr> 
                <tr >
                    <td> مجموع المبالغ المقبوضة من جاري الشركاء  </td>
                    <td> {{ final_cash.sum_old_supplier_import_period }} </td>
                </tr> 
                
                <tr > 
                    <td> <h3>النقد النهائي  </h3> </td>  
                    <td> <h3>{{ final_cash.final_cash }}</h3>  </td> 
                </tr>
                  </table>
          </v-col>
          <v-col></v-col>
        
        </v-row>
    </v-container>
</template>
<script>
import tools from '@/plugins/tools'
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            cashdate:true
        }
    },
    name:'accountsPage',
    computed:mapGetters(['budget','final_cash']),
    methods:{
      ...mapActions(['f_budget','f_final_cash'])
    },
    
    mounted(){
        tools.checkPermissions('accounting-budget')
      this.f_budget() 
    }
    
}
</script>

<style scoped>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #04AA6D;
    color: white;
}
</style>
