import axios from 'axios'; 

const state={
    contructors_n:[],   
    customers_n:[], 
    employees_n:[],  
    projects_n:[],
    engineers_n:[],
    users_name:[], 

}

const  getters={
    contructors_n:(state)=>state.contructors_n, 
    customers_n:(state)=>state.customers_n,
    employees_n:(state)=>state.employees_n,
    projects_n:(state)=>state.projects_n,
    engineers_n:(state)=>state.engineers_n,
    users_name:(state)=>state.users_name,
}

const mutations= { 
    setContructors_n:(state,value)=>state.contructors_n=value,   
    setCustomers_n:(state,value)=>state.customers_n=value,  
    setEmployees_n:(state,value)=>state.employees_n=value,  
    setProjects_n:(state,value)=>state.projects_n=value,  
    setEngineers_n:(state,value)=>state.engineers_n=value,  
    setUsers_name:(state,value)=>state.users_name=value,  
} 
const actions={ 
    f_constructors_n({commit}){
        axios.get("a/per/names/constructor")
        .then((res) => { 
           commit('setContructors_n',res.data.constructors)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },  
    f_customers_n({commit}){
        axios.get("a/per/names/customer")
        .then(res => { 
           
           commit('setCustomers_n',res.data.customers)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    }, 
    f_employees_n({commit}){
        axios.get("a/per/names/employee")
        .then(res => {  
           commit('setEmployees_n',res.data.employees)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    },  
    f_projects_n({commit}){
        axios.get("a/project/names")
        .then(res => { 
        
           commit('setProjects_n',res.data.projects)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    }, 
    f_engineers_n({commit}){
        axios.get("a/employee/all_engineers")
        .then(res => { 
           
           commit('setEngineers_n',res.data.all_engineers)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    }, 
    f_users_name({commit}){
        axios.get("a/user/get_name")
        .then(res => {   
           commit('setUsers_name',res.data.users)  
        })
        .catch(err => {
            console.error(err); 
        }) 
    }, 
}

export default {
    getters,
    state,
    actions,
    mutations
}