<template>
  <div>
     <div >
      <v-container v-if="isAdded" >
        <v-row> 
          <v-col  cols="12">  
            <v-alert type="success" >
              تم حفظ جيع البيانات 
            </v-alert>
          </v-col> 
          <!-- <v-col md="3">
            <v-btn @click="print()" color="success">طباعة <v-icon>mdi-printer</v-icon></v-btn>
           </v-col> -->
           <v-col md="3">
            <v-btn to="/" class="m-2" color="primary">رجوع الى الصفحة الرئيسة <v-icon>mdi-arrow-right</v-icon></v-btn>
           </v-col> 
        </v-row>
      </v-container>
      <!-- <v-btn v-if="!isAdded"  @click="next()" class="text-end my-2" color="success">حفظ</v-btn>

      <vueEditor id="print" :disabled="isAdded" v-model="text"/> -->
     </div>
  </div>
</template>
<script>
// import { VueEditor } from "vue2-editor";

import axios from 'axios'
import printing from '@/plugins/print';
export default {
    name:'addProjectContract',
    data(){
        return{
            text:'',
            isAdded:true,
            contract:''
        }

    },
    props:['project_id'],
    // components:{VueEditor},
    created(){
        axios.get('a/contract/project-data/'+this.project_id)
        .then(res => { 

            this.text=res.data.setting.second_party+`
            الطرف الأول (المالك) : ${res.data.customer.name}
            العنوان / ${res.data.customer.address}
            الموبايل : ${res.data.customer.phone}
            `+res.data.setting.project_contract_clauses
        })
        .catch(err => {
            console.error(err);
        })
    },
    methods:{
        next(){
            let params={}
            params.contract_number=100000
            params.date_signature="2000-1-1";
            params.title='عقد المشروع';
            params.project_id=this.project_id;
            params.contract_clauses=this.text;

            axios.post('a/contract/add',params)
            .then(() => { 
              this.isAdded=true;
              this.getContract()
            })
            .catch(err => {
              console.error(err); 
            })
        },
        getContract(){
          let params={}
          params.project_id=this.project_id;
          axios.post('a/contract/get',params)
          .then(res => { 
            this.contract=res.data.contract.contract_clauses;
            this.isAdded=true;
          })
          .catch(err => {
            console.error(err); 
          })
        },
           print() { 
             printing(['print'])
            }, 

    }
}
</script>