<template>
  <!-- App.vue -->
   <div class="body">
      <router-view></router-view>
   </div>
</template>

<script>  

export default {
  name: 'App', 

};
</script>
<style > 

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&family=Berkshire+Swash&display=swap');
.body{ 
   font-family: 'Almarai', sans-serif;
   
}  

</style>
