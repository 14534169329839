<template>
    <div>
    <v-container  class="text-center my-5" >
           <v-row>
            <v-col>  
              <vueEditor id="print" :disabled="isSave"  v-model="contract"/>
            </v-col>
           
           </v-row>
           <div class="text-start my-3">
               <v-btn class="mx-2 "  color="success" @click="saveChanges()" v-if="!isSave">حفظ</v-btn>
               <v-btn color="error" to="/mainSetting"  >رجوع</v-btn>
                <v-alert class="mx-2 my-2" type="success" v-if="isSave">
                تم الحفظ التعديل
               </v-alert>
           </div>
          </v-container> 
    </div> 
</template>
<script> 
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
    name:"ProjectContract",
    components:{VueEditor},
    data(){
        return{
            text:'', 
            contract:'',
            isSave:false
        }

    },
    methods:{
        getContract(){ 
          axios.get('a/setting/select/project_contract_clauses')
          .then(res => {
            
            this.contract=res.data.project_contract_clauses; 
          })
          .catch(err => {
            console.error(err); 
          })
        },
        saveChanges(){
          let params={}
          params.col_name='project_contract_clauses'
          params.col_value=this.contract
          axios.post('a/setting/update',params)
          .then(() => {
             
            this.isSave=true
          })
          .catch(err => {
            console.error(err); 
          })
        }
    },
    created(){
        this.getContract()
    }

}
</script>