<template>
    <v-container fluid>
      <h3 class="success--text " v-if="project.is_complete">هذا المشروع مكتمل</h3>
      <h3 class="red--text " v-else>هذا المشروع غير مكتمل
      </h3>
      <br> 
  <v-row justify="center"> 
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          هل انت متأكد من حذف مشروع {{project.name}} بشكل نهائي
        </v-card-title>

        <v-card-text>
          سيتم حذف مشروع {{project.name}} بشكل نهائي مع جميع البيانات المرتبطة به
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            الغاء
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="deleteThisProject(project.id)"
          >
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
      <v-row>
        <v-col cols="12" md="6" > 
          <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > الاسم :  {{project.name}}</h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > رقم المشروع :  {{project.project_number}}</h3> 
                </v-alert>
            </v-col>
            
            <v-col cols="12" md="6" > 
                <v-alert color="red darken-2"> 
                  <h3 class="white--text " > العنوان :  {{project.address}}</h3> 
                </v-alert>
            </v-col><v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > تاريخ البدء :  {{project.start_date}}</h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="green darken-4"> 
                  <h3 class="white--text " > نسبة الفائدة  :  {{ project.profit }}%</h3> 
                </v-alert>
            </v-col><v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " >  مبلغ الاعتماد :  {{project.expense_limit}} دع  </h3> 
                </v-alert>
            </v-col><v-col cols="12" md="6" v-if="project.customer"> 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > المالك :  {{project.customer.name}}</h3> 
                </v-alert>
            </v-col><v-col cols="12" md="6" v-if="project.area" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > المساحة :  {{project.area.quantity}}  m²</h3> 
                </v-alert>
            </v-col><v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 v-if="project.project_type" class="white--text " > نوع العمل :  {{project.project_type.name}}</h3> 
                </v-alert>
            </v-col> 
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > مده المشروع :  {{project.project_duration}} يوم</h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 v-if="project.report" class="white--text " > الانجاز المالي :  {{project.report.financial_achievement}}</h3> 
                </v-alert>
            </v-col> 
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 v-if="project.report" class="white--text " > الانجاز الزمني :  {{project.report.time_achievement}}</h3> 
                </v-alert>
            </v-col> 
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " > تاريخ انتهاء المشروع :  {{project.end_date}}</h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " >  المده المنقضية   :  {{project.executed_days}} يوم  </h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2">   
                  <h3 class="white--text " >  المده المتبقية   :  {{project.remaining_days}} يوم </h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2">   
                  <h3 class="white--text " >  مهندس المشروع  :  </h3> 
                  <h3 class="white--text " v-for="eng in project.engineer" :key="eng.id" >     {{eng.name}} </h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" md="6" > 
                <v-alert color="blue darken-2"> 
                  <h3 class="white--text " >التكلفة التخمينية:  {{project.estimated_cost}} دع </h3> 
                </v-alert>
            </v-col>
            <v-col cols="12" > 
                <v-alert color="red darken-5"> 
                  <h3 class="white--text " > الملاحضات :  {{project.notes}}</h3> 
                </v-alert>
            </v-col>
      <v-btn class="my-3" color="success" :to="'/editProject/'+project.id">تعديل المعلومات  <v-icon>mdi-pen</v-icon></v-btn>
      <v-btn class="my-3 mx-3" color="red" dark @click="dialog = true" >حذف المشروع    <v-icon>mdi-delete</v-icon></v-btn>

        </v-row>
    </v-container>
</template>
<script>
import tools from '@/plugins/tools'
import { mapActions, mapGetters } from 'vuex'
export default {
    props:['id'], 
    name:'projectInfo',
    data(){
      return{
        dialog :false,
      }
    },
    mounted(){ 
        this.f_project(this.id)
    },
    computed:mapGetters(['project']),
    methods:{ ...mapActions(['f_project','deleteProject']),
    deleteThisProject(id){
      tools.checkPermissions('project-delete')
      this.deleteProject(id)

    }
  }
     
}
</script>