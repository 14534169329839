<template>
  <v-container class="fs-2" fluid> 
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field 
      label="بحث"  
      v-model="query.name"
      reverse
      outlined
      key.up=""
      @keyup.enter="searchOpject"
    ></v-text-field>
      </v-col>
      <v-col>
        <v-btn @click="searchOpject()" color="success">بحث</v-btn>
        <!-- <v-spacer></v-spacer> -->

        <v-btn  color="blue" dark small class="my_btn ma-3" @click="dialogSorting=true">
            <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث التفصيلي
        </v-btn> 
      </v-col>
      <v-dialog v-model="dialogSorting" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                البحث حسب
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="query.name" label="اسم المشروع" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="query.address" label="العنوان" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="query.profit" type="number" label="نسبة الربح" reverse>
                            </v-text-field>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined   v-model="query.project_type_id" :items="projectType" item-text="name" item-value="id" label="نوع المشروع "></v-autocomplete>
                        </v-col>   
                        <v-col dir="ltr"  cols="12" sm="6" md="4"> 
                          <v-autocomplete reverse outlined   v-model="query.is_complete" :items="[{id:'2', name:'كل المشاريع'},{id:'1', name:'مكتمل'},{id:'0', name:'غير مكتمل'} ]" item-text="name" item-value="id" label="الانجاز "></v-autocomplete>
                        </v-col> 
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogSorting=!dialogSorting" dark color="red">الغاء</v-btn>
                <v-btn dark class="mx-5" @click="query={}" color="red">تفريغ <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="searchOpject()" color="success">بحث</v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
    </v-row>
  
    <v-data-iterator  
      :items="projects"  
      hide-default-footer
      :items-per-page="15" 
    >
      <template v-slot:header> 
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider> 
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="12"
            md="6"
            lg="3"
          >
            <v-card class="bg-main my_btn" >
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }} 
                <v-spacer class="white--text"></v-spacer>
               <v-btn icon class="my_btn" :to="'/projectPage/'+item.id" color="success aline-end"><v-icon>mdi-book-arrow-left</v-icon></v-btn> 
              </v-card-title> 
              <v-divider></v-divider>
              <v-list class="bg-main"  dense>
                <!-- data -->
                <v-list-item>
                  <v-list-item-content>العنوان:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.address }}</b> 
                  </v-list-item-content>
                </v-list-item> 
                <!-- <v-list-item>
                  <v-list-item-content> النسبة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.profit_percent }}</b>
                  </v-list-item-content>
                </v-list-item>  -->
                <v-list-item>
                  <v-list-item-content>نوع المشروع:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.project_type.name }}</b>
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item class="green--text" >
                  <v-list-item-content>مجموع  الدفعات المستلمة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.total_import }} دع</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item class="red--text" >
                  <v-list-item-content >مجموع المبالغ المصروفة:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.total_export }} دع</b>
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item>
                  <v-list-item-content>ارباح المكتب:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.profit }} دع</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item>
                  <v-list-item-content>نسبة فائدة المكتب:</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.profit }} %</b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item :class="{'red white--text': item.report.import_sub_export  < 2000000 }">
                  <v-list-item-content>  صافي المبلغ المتبقي:</v-list-item-content>
                  <v-list-item-content class="align-end">
                  <b>{{ item.report.project_box }} دع</b>  
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item :class="{'red white--text': item.report.import_sub_export  < 1 }">
                  <v-list-item-content> اجمالي المبلغ المتبقي:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.import_sub_export }} دع</b>
                  </v-list-item-content>
                 </v-list-item>
                 <v-list-item >
                  <v-list-item-content> الانجاز الزمني :</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.time_achievement  }} </b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item >
                  <v-list-item-content> عدد الايام المتبقية :</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.days_left  }} </b> 
                  </v-list-item-content>
                </v-list-item> 
                <v-list-item >
                  <v-list-item-content> الانجاز المالي :</v-list-item-content>
                  <v-list-item-content class="align-end">
                   <b>{{ item.report.financial_achievement  }} </b> 
                  </v-list-item-content>
                </v-list-item> 
                <!-- <v-list-item>
                  <v-list-item-content>الخلاصة : </v-list-item-content>
                  <v-list-item-content class="align-end">
                    <b>{{ item.report.import_sub_percentage }} دع</b>
                  </v-list-item-content>
                </v-list-item> -->
              
                <!-- end data -->

              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-pagination
        class="ma-3"
      
        prev-icon="mdi-chevron-right"
        next-icon="mdi-chevron-left"
        v-model="projectsPages.current_page"
        :length="projectsPages.last_page"
        @input="event=> changePage(event)"
         ></v-pagination>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import router from '@/router';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "projectsPage",
  data(){
      return{
        search: '',
        query:{},
        dialogSorting:false,
        itemsPerPageArray: [4, 8, 12],
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 4,
        sortBy: 'name',
        keys: [
          'Name', 
        ],
        projectType: [
        { id: 3, name: "اشراف" },
        { id: 2, name: "تنفيذ" },
        { id: 1, name: "تصميم" },
      ],
       
      }
  },
  computed:{
    ...mapGetters(['projects','projectsPages']),
     
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
  },
  methods:{
    ...mapActions(['f_projects']),
    changePage(e){ 
          this.query.page=e
          this.f_projects(this.query)

          let queryString = new URLSearchParams(this.query).toString();
          window.history.pushState({}, '', '/projects?' + queryString); 
    }   ,

    searchOpject(){
      // this.query.per_page=2
      this.query.page=1 
      this.query.last_page=this.projectsPages.last_page

       
      let queryString = new URLSearchParams(this.query).toString();
      window.history.pushState({}, '', '/projects?' + queryString); 

      this.f_projects(this.query)

      this.dialogSorting=false
      this.query.page=this.projectsPages.current_page 
      this.query.last_page=this.projectsPages.last_page

    }
  },
    

  mounted(){
    this.f_projects(router.history.current.query)
    this.query=router.history.current.query

    
  },
 
  
};
</script>
