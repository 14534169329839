<template>
<v-container class="full_height">

    <v-card class="bg-main halve_height">

        <form class="form text-center  mt-6 " dir="rtl" @submit.prevent="submit">
            <v-row>
                <v-col>
                    <div class="text-center  ">
                        <img height="120" width="310" src="../../assets/logo.png" alt="">
                    </div>
                </v-col>
            </v-row>
            <v-row >
              <v-col > 
                <v-text-field reverse  v-model="loginInfo.email" :rules="[rules.email]" label="الايميل او اسم المستخدم"  required  ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field reverse v-model="loginInfo.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min(loginInfo.password)]" :type="show ? 'text' : 'password'" label="كلمة السر" @click:append="show = !show"></v-text-field>
            <div class="errMessage">{{errMessage.toString()}}</div>

            <v-btn type="submit" color="green" class="btn_a">
                تسجيل الدخول
            </v-btn>
        </form>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
    mapMutations
} from 'vuex'
import axios from 'axios'
import router from "@/router"

export default {
    name: "loginPage",
    methods: {
        ...mapActions(['login']),
        ...mapMutations(['setUser']),

        submit() {
            axios.post('/user/login', this.loginInfo)
                .then(res => {
                    this.setUser(res.data)

                    localStorage.setItem('token', res.data.token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
                    localStorage.setItem('name', res.data.person.name)
                    localStorage.setItem('image', res.data.person.image)
                    localStorage.setItem('permissions', JSON.stringify(res.data.permissions)) //save data as json *JSON.parse()

                    localStorage.setItem('id', res.data.person.id)
                    router.push('/')
                })
                .catch(err => {
                    this.errMessage = err.response.data.errors.email

                })

        },
    },
    data: () => ({
        errMessage: "",
        loginInfo: {
            email: '',
            password: '',
        },
        show: false,
        rules: {
            required: value => !!value || 'أدخل كلمة السر',
            email: value => !!value || 'أدخل  الايميل',
            min: v => v.length >= 8 || 'يجب ان يكون على الاقل 8 احرف او ارقام',
            emailMatch: () => (`The email and password you entered don't match`),
        },
    }),

}
</script>

<style scoped>
.form {
    margin: 60px;
    margin-bottom: 30%;
}

.btn_a {
    background-color: rgb(83, 196, 83);
    color: green;
    margin-bottom: 30px;
}

.text-center {
    text-align: center;
}

.errMessage {
    color: rgb(230, 57, 57);
    margin-bottom: 10px;

}

.halve_height {
    height: 50vh;

}
.full_height{
  height: 100vh;

}
</style>
