var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"body",attrs:{"id":"fullscreen","dir":"rtl"}},[_c('v-app-bar',{staticClass:"hiden",attrs:{"color":"main","app":""}},[_c('v-app-bar-nav-icon',{attrs:{"color":"blue"},on:{"click":function($event){_vm.drawer=!_vm.drawer}}}),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my_btn",attrs:{"color":"blue","small":"","icon":""},on:{"click":_vm.f_my_safes}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-safe")])],1)]}}])},[_c('v-list',_vm._l((_vm.my_safes),function(safe){return _c('v-list-item',{key:safe.id},[_c('v-list-item-title',[_vm._v(" "+_vm._s(safe.name)+" : "+_vm._s(safe.balance)+" ")])],1)}),1)],1),_c('v-btn',{staticClass:"my_btn mx-3",attrs:{"small":"","to":"/mainSetting","color":"blue","icon":""}},[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"content":_vm.notificationsCount,"value":_vm.notificationsCount,"color":"green","overlap":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"my_btn ",attrs:{"color":"blue","small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{staticClass:"notifications"},_vm._l((_vm.notifications),function(noti){return _c('v-list-item',{key:noti.id,on:{"click":function($event){return _vm.read(noti.id)}}},[_c('v-card',{staticClass:"my-2",attrs:{"elevation":"0","disabled":noti.is_watched}},[_c('div',[_vm._v(_vm._s(noti.title))]),_c('div',[_vm._v(_vm._s(noti.created_at[0]))])])],1)}),1)],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/changePassword"},on:{"click":function () {}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-lock-outline")]),_vm._v(" تغيير كلمة السر ")],1)],1),_c('hr'),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',{staticClass:"text--red",attrs:{"color":"red"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-logout")]),_vm._v(" تسجيل الخروج ")],1)],1)],1)],1),_c('div',{staticClass:"mx-2 text_black"},[_vm._v(_vm._s(_vm.person.name))]),_c('v-avatar',{attrs:{"size":"40","color":"blue"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1)],1),_c('v-navigation-drawer',{staticClass:"bg-main hiden",attrs:{"id":"hidden","right":"","color":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"text-center my-1"},[_c('img',{attrs:{"height":"80","width":"200","src":require("../../assets/logo.png"),"alt":""}})]),_c('hr'),_c('v-list',[_c('v-list-item-group',{attrs:{"color":"blue"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.mainMenu),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mx-1",domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }