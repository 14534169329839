import axios from "axios"
const state = {
    safes: [], 
    transSafe:[],
    my_safes:[],
    all_safes:[],
}

const getters = {
    safes: (state) => state.safes,  
    transSafe: (state) => state.transSafe,  
    my_safes: (state) => state.my_safes,  
    all_safes: (state) => state.all_safes,  
}

const mutations = {
    setSafes: (state, safes) => state.safes = safes, 
    setTransSafe: (state, transSafe) => state.transSafe = transSafe, 
    setMy_safes: (state, my_safes) => state.my_safes = my_safes, 
    setAll_safes: (state, all_safes) => state.all_safes = all_safes, 

}
const actions = {
    f_transSafe({commit},params){
        console.log(params);
        axios.post('a/safe/trans',params)
        .then(res => {
            commit('setTransSafe',res.data.transaction_safe)
            console.log(res.data.transaction_safe)
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    //admin safes
    f_safes({commit}){
        axios.get('a/safe/get_admin')
        .then(res => {
            commit('setSafes',res.data)
            
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    //my safes
    f_my_safes({commit}){
        axios.get('a/safe/my_safes')
        .then(res => {
            commit('setMy_safes',res.data.my_safes)
            
        })
        .catch(err => {
            console.error(err); 
        })
    }, 

    //all safes
    f_all_safes({commit}){
        axios.get('a/safe/all')
        .then(res => {
            commit('setAll_safes',res.data.safes
            )
            
        })
        .catch(err => {
            console.error(err); 
        })
    }, 
    // transactions money
    transMoney({dispatch},params){
        axios.post('a/safe/trans/add',params)
        .then(res => {
            console.log(res)
            dispatch('f_transSafe')
        })
        .catch(err => {
            console.error(err); 
        })
    }
}

export default {
    getters,
    state,
    actions,
    mutations
}