 <template>
  <div>
    <h1 class="text-center my-10">مرحبا بكم بالنظام المحاسبي</h1>
  </div>
 </template>
<script>
// import axios from 'axios'
export default {
  data(){
    return{}

  },
  // created(){
  //   axios.get('a/scheduler')
  //   .then(res => {
  //     console.log(res)
  //   })
  //   .catch(err => {
  //     console.error(err); 
  //   })
  // }
}
</script>
 