import router from '@/router';
import axios from 'axios'; 

const state={ 
    project:[],
    projects:[],
    projectsPages:[], 
    projectExpenses:[],
    projectExpensesReport:[],
    projectIncomes:[],
    projectDesignes:[],
    projectImages:[],
    erorrs:[],
    success:[],
}

const  getters={ 
    project:(state)=>state.project,
    projects:(state)=>state.projects,
    projectsPages:(state)=>state.projectsPages,
    projectExpenses:(state)=>state.projectExpenses,
    projectExpensesReport:(state)=>state.projectExpensesReport,
    projectIncomes:(state)=>state.projectIncomes,
    projectDesignes:(state)=>state.projectDesignes,
    projectImages:(state)=>state.projectImages,
    
    erorrs:(state)=>state.erorrs,
    success:(state)=>state.success,
}

const mutations= { 
    setProject:(state,project)=>state.project=project,
    setProjects:(state,projects)=>state.projects=projects,
    setProjectExpenses:(state,projectExpenses)=>state.projectExpenses=projectExpenses,
    setProjectExpensesReport:(state,projectExpensesReport)=>state.projectExpensesReport=projectExpensesReport,
    setProjectIncomes:(state,projectIncomes)=>state.projectIncomes=projectIncomes,
    setProjectDesignes:(state,projectDesignes)=>state.projectDesignes=projectDesignes,
    setProjectImages:(state,projectImages)=>state.projectImages=projectImages,
    setErorrs:(state,erorrs)=>state.erorrs=erorrs,
    setSuccess:(state,success)=>state.success=success,
    setProjectsPages:(state,projectsPages)=>state.projectsPages=projectsPages
} 
const actions={   
    f_project({commit},id){
        axios.get('a/project/show/'+id)
        .then(res => { 
            console.log(res.data.project)
            commit('setProject',res.data.project)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_projects({commit},params){
        axios.post('a/project',params)
        .then(res => {
            
            commit('setProjects',res.data.data)

            commit('setProjectsPages',res.data.meta)
        })              
        .catch(err => {
            console.error(err);   
        })
    },
    f_projectExpenses({commit},params){
        params.is_in_project=1
        axios.post('a/ex/search',params)
        .then(res => {
            
            commit('setProjectExpenses',res.data.expenses)
            commit('setProjectExpensesReport',res.data.report)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    deleteProject(x,id){
        axios.get('a/project/delete/'+id)
        .then(() => {
            
            router.push('/projects')
        })
        .catch(err => {
            console.error(err); 
        })
    }
    
}

export default {
    getters,
    state,
    actions,
    mutations
}