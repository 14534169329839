<template>
   
<v-container fluid>  <h2 class="text-center">تغيير كلمة السر</h2>
<br>
    <template>
      
        <v-spacer></v-spacer>
        <!-- main table -->
        <v-container>
            <v-alert :value="saved" type="success">تم حفظ البيانات</v-alert>
            <v-row>
                <v-col cols="12" >
                    <v-text-field reverse outlined v-model="editedItem.current_password" :rules="[(val) => ((val || '').length > 7 ) || 'يجب ان تتكون من 8 مراتب ']" required label="كلمة السر الحالية"></v-text-field>
                </v-col>
                <v-col cols="12" >
                    <v-text-field reverse outlined v-model="editedItem.password" :rules="[(val ) => ((val || '').length > 7 && /\d/.test(val) && /[a-zA-Z]/.test(val)) || 'يجب ان تتكون من 8 مراتب وتحتوي على ارقام وحروف انكليزية']" required label="كلمة السر الجديده"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field reverse outlined v-model="editedItem.password_confirmation" :rules="[(val ) => (val || '') == editedItem.password || 'كلمة المرور غير متطابقة']" required label="اعادة كتابة كلمة السر الجديده"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-alert :value="errMessage.length" type="error">{{ errMessage }}</v-alert>

                </v-col>
             <v-col cols="12" >
                    <v-btn dark color="green" @click="editMyInfo">حفظ البيانات</v-btn>
                </v-col>
            </v-row>

        </v-container>
    </template>
</v-container>
</template> 
<script>
import axios from 'axios';
export default {
    data(){
        return{ 
            editedItem:{},
            errMessage:'',
            saved:false 
        }

    },
    methods:{
        editMyInfo(){  
           axios.post('a/user/update/reset',this.editedItem)
           .then(res => {
            this.saved=true
            console.log(res)
           })
           .catch(err => {
            console.log(err.response); 
            this.errMessage=err.response.data.message

            console.error(err); 
           })
        }
    }
}
</script>