<template>
<v-container fluid>
    <template>

        <!-- main table -->
        <v-data-table :headers="headers" :search="search" :items="roles" class="elevation-0 my_btn bg-main" :footer-props="{
            'items-per-page-text':'عدد العناصر',
              'next-icon':'$prev',
              'prev-icon':'$next', 
            }">
            <template v-slot:top>
                <v-toolbar flat class="bg-main">
                    <!-- header -->
                    <v-toolbar-title>الصلاحيات</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <!-- search -->
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <!-- add and delete dialog -->
                    <v-dialog v-model="dialog" max-width="700px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">أضافة<v-icon>mdi-plus</v-icon>
                            </v-btn>

                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <!-- dialog add and edit  -->
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.name" :roles="rolesForm.name" required label="الاسم"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-autocomplete   deletable-chips outlined chips multiple v-model="editedItem.permissions" :items="permissions" item-text="display_name" item-value="id" label="الصلاحيات"></v-autocomplete>
                                        </v-col>
                                        <div v-if="editedIndex!=-1">* بعد تعديل الصلاحيات يتم تسجيل الخروج التلقائي لكل المرتبطين بهذه الصلاحيات</div>

                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="close"> إلغاء </v-btn>
                                <v-btn color="green" :disabled="!formIsValid" type="submit" @click="save"> حفظ </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <!-- delete dialog -->
                            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <!-- actions of table -->
            <template v-slot:[`item.operation`]="{item}">
                <v-icon color="" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:[`item.permissions`]="{item}">
                <span class="mx-1" v-for="( a , index ) in item.permissions" :key="index">
                    {{ index+1 }} - {{ a.display_name }}
                </span>
            </template>
            <template v-slot:no-data>
                <v-card loading>
                    <v-btn> انتظر..... </v-btn>
                </v-card>
            </template>
        </v-data-table>
    </template>
</v-container>
</template>

  
<script>
import tools from '@/plugins/tools'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    name: 'unitsCard',
    data: () => ({
        dialog: false,
        dialogDelete: false,
        search: '',
        rolesForm: {
            name: [val => (val || '').length > 0 || 'ادخل الاسم '],
        },
        headers: [{
                text: 'الاسم',
                value: 'name',
                align: 'start',
                sortable: false,
            },
            {
                text: 'الصلاحيات',
                value: 'permissions',
                align: 'start',
                sortable: false,
            },
            {
                text: 'العمليات',
                value: 'operation',
                align: 'end',
                sortable: false
            },
        ],
        editedIndex: -1,
        editedItem: {
            name: '',
        },
        defaultItem: {
            name: '',
        }
    }),

    computed: {
        ...mapGetters(['roles', 'permissions']),
        formTitle() {
            return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
        },
        formIsValid() {
            return (
                Boolean(this.editedItem.name)
            )
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        tools.checkPermissions('role-form')

        this.f_role()
        this.f_permissions()
    },
    updated() {
        // this.fetchUnits()
    },

    methods: {
        ...mapActions(['f_role', 'f_permissions', 'deleteCard', 'addCard', 'updateCard']),

        editItem(item) {
            this.editedIndex = this.roles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.roles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            let card = [];
            card.type = "role"
            card.id = this.roles[this.editedIndex].id
            this.deleteCard(card)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() { //save dialog add and edit
            if (this.editedIndex > -1) {
                //update item  
                let card = [];
                card.type = 'role'
                card.id = this.roles[this.editedIndex].id
                card.data = this.editedItem;

                this.updateCard(card)
            } else {
                //add new item   
                let card = [];
                card.type = 'role'
                card.data = this.editedItem;
                this.addCard(card)
            }
            this.close()
        },

    },
}
</script>
