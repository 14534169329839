<template>
  <v-container fluid> 
    <v-tabs 
      dir="ltr"
      color="blue"
      background-color="main"
      v-model="tab"
      fluid 
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab
        v-for="item in items"
        :key="item.tab" 
        :to="item.path"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <router-view></router-view>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        tab: null,
        items: [
          { tab: 'المتعهدين', path:'contractorsCard' },
          { tab: 'الموظفين', path: 'employeesCard' },
          { tab: 'وحدات القياس', path: 'unitsCards' },
          { tab: 'مراحل العمل', path: 'constructionCard' },
          { tab: 'ابواب الصرف', path: 'expensesTypeCard' },  
          { tab: 'المالكين', path: 'customersCard' },   
          { tab: 'انواع الموظفين', path: 'employeeTypesCard' },   
          { tab: 'انواع المتعهدين', path: 'contractorTypesCard' },   
          { tab: 'الصلاحيات', path: 'permissionsCard' },   
          { tab: 'المستخدمين', path: 'usersCard' },   
          { tab: 'القاصات', path: 'safes' },   
        ],
      }
    },
  }
</script>
