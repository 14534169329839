import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'; 


import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css' 



Vue.use(Vuetify); 

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',  
      },
      theme: {
        themes: {
          light: {
            red: '#E4513F', // #E53935
            black_c:'#4C657F',
            dark_blue:'#4A69B9',
            secondary: colors.red.lighten4, // #FFCDD2
            blue: '#2196F3', // #3F51B5
            background: '#E0E5EB', 
            main:"#E0E5EB",
            yelloo:'#F6B748'
          },
        },
      },
});
