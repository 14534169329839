<template>
    <v-container >
      
      <v-row class="mt-3">
        <v-col cols="12" sm="6" md="4"> 
          <v-autocomplete
          md-6
          outlined
          reverse
          label="المشروع"
          :items="completionTypes"
          item-text="title"
          item-value="value"
          v-model="SearchParams.project_is_complete" 
        ></v-autocomplete>
      </v-col>
            <v-col   dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete reverse outlined v-model=" SearchParams.project_id" :items="projects_n" item-text="name" item-value="id" label="اختر المشروع "></v-autocomplete>
            </v-col>
            <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete reverse outlined chips multiple v-model="SearchParams.expense_type_id" :items="expensesType" item-text="name" item-value="id" label="التبويب"></v-autocomplete>
            </v-col>
            <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete reverse outlined chips multiple v-model="SearchParams.construction_category_id" :items="constructions" item-text="name" item-value="id" label="مرحلة العمل"></v-autocomplete>
            </v-col>
            <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete reverse outlined chips multiple v-model="SearchParams.person_id" :items="contructors_n" item-text="name" item-value="person_id" label="المتعهد"></v-autocomplete>
            </v-col>
        <v-col cols="12" sm="6" md="4"> 
           <v-autocomplete
            md-6 
            outlined
            reverse
            label=" التقرير حسب"
            :items="groupTypes"
            item-text="title"
            item-value="value"
            v-model="SearchParams.group_type" 
          ></v-autocomplete>
        </v-col>
        <v-col cols="1"><v-btn @click="fetchDataOfExpensesGroup" dark color="blue">تحديث</v-btn></v-col>
      </v-row> 
      <v-data-table   
      :headers="headers" 
      :items="Tdata"   
       class="elevation-0 my_btn bg-main mt-5 "
      :footer-props="{
        'items-per-page-text':'عدد العناصر',
        'next-icon':'$prev',
        'prev-icon':'$next', 
      }"
  >
  <template v-slot:top>
    <v-toolbar
    flat class="bg-main"
    >
    <!-- header -->
    <v-toolbar-title >التقرير التجميعي</v-toolbar-title>
    <v-divider
    class="mx-4"
    inset
    vertical
    ></v-divider>
    <v-spacer></v-spacer>
    <v-btn class="mb-2 mx-2 my_btn elevation-0" color="main" @click="print()">
        <v-icon color="blue">mdi-printer-outline</v-icon>
    </v-btn> 
         
  </v-toolbar>
</template>
<template v-slot:[`item.name`]="{item}"> 
  <table v-if="item"  style="width: 100%;    border-collapse: collapse;" >   
    <tr >
    <th>المشروع</th>
    <th>الاسم</th>
    <th>المبلغ الكلي</th>
    <th>المبلغ المدفوع</th>
    <th>المبلغ المتبقي</th>
  </tr>
  <tr  v-for="(i, index) in item" :key="index"> 
    
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; "> {{ i.project_name }}</td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; "> {{i.name}} </td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; ">   {{ i.total }} </td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; " >{{ i.paid }}</td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; ">    {{ i.rest }} </td>
    <hr>
    <h4 class="ma-2" v-if="i.sum_total">المجموع : {{ i.sum_total }} مجموع المدفوع : {{ i.sum_paid }} مجموع المتبقي : {{ i.sum_rest }} </h4>
    </tr>
  </table> 
  <div v-else> لاتوجد بيانات
  </div>
</template>
<!-- actions of table -->

<template v-slot:no-data>
  <v-card 
  ><v-btn > لاتوجد بيانات </v-btn>
</v-card>
</template>
</v-data-table>
<v-row v-if="sum_report" class="my-5 text-center">
            <v-col cols="12" md="6" lg="4" class="elevation-0">
                                <v-alert type="info" class="my_btn" border="right">
                    المبلغ الكلي  : {{sum_report.sum_all_total}}
                </v-alert>
            </v-col>
           
            <v-col cols="12" md="6" lg="4" class="elevation-0">
                                <v-alert type="success" class="my_btn" border="right">
                    المبلغ الكلي المدفوع: {{sum_report.sum_all_paid}}
                </v-alert>
            </v-col>
             <v-col cols="12" md="6" lg="4" class="elevation-0">
                <v-alert type="error" class="my_btn" border="right">
                    المبلغ الكلي المتبقي: {{sum_report.sum_all_rest}}
                </v-alert>
            </v-col>

        </v-row>

 <!-- to print -->
   <v-dialog v-model="dialogPrint">
        <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
        <v-data-table   
        id="table"
        class="p_right"
      :headers="headers" 
      :items="Tdata"    
      hide-default-footer
      disable-pagination
     
  >
  <template v-slot:top>
    <v-toolbar
    flat class="bg-main"
    >
    <!-- header --> 
    <v-divider
    class="mx-4"
    inset
    vertical
    ></v-divider>
    <h3>التقرير التجميعي </h3> 

  </v-toolbar>
</template>
<template v-slot:[`item.name`]="{item}"> 
  <table style="width: 100%;    border-collapse: collapse;" >   
    <tr >
    <th style="border: 1px solid black; padding: 5px; ">المشروع</th>
    <th style="border: 1px solid black; padding: 5px; ">الاسم</th>
    <th style="border: 1px solid black; padding: 5px; ">المبلغ الكلي</th>
    <th style="border: 1px solid black; padding: 5px; ">المبلغ المدفوع</th>
    <th style="border: 1px solid black; padding: 5px; " >المبلغ المتبقي</th>
  </tr>
  <tr v-for="(i, index) in item" :key="index">  

    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; "> {{ i.project_name }}</td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; "> {{i.name}} </td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; ">   {{ i.total }} </td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; " >{{ i.paid }}</td>
    <td v-if="!i.sum_total" style="border: 1px solid black; padding: 5px; ">    {{ i.rest }} </td>
    
    <td v-if="i.sum_total"  ></td>
    <td v-if="i.sum_total"   ></td>
    <td v-if="i.sum_total" style="border: 1px solid black; padding: 5px; ">  <h4 class="ma-2" >المجموع : {{ i.sum_total }} </h4> </td>
    <td v-if="i.sum_total" style="border: 1px solid black; padding: 5px; ">  <h4 class="ma-2" > مجموع المدفوع : {{ i.sum_paid }} </h4> </td>
    <td v-if="i.sum_total" style="border: 1px solid black; padding: 5px; ">  <h4 class="ma-2" > مجموع المتبقي : {{ i.sum_rest }} </h4> </td>
      </tr>
  </table> 
</template> 
<template v-slot:no-data>
  <v-card
  loading
  ><v-btn > انتظر..... </v-btn>
</v-card>
</template>
</v-data-table>

       <div class="p_right" id="footer">
          <br>
          <span class="p_box">المبلغ الكلي :{{sum_report.sum_all_total}}</span> 
          <span class="p_box">مجموع المبلغ المدفوع :{{sum_report.sum_all_paid}}</span> 
          <span class="p_box">مجموع المبلغ المتبقي :{{sum_report.sum_all_rest}}</span> 
          
        </div>

  
    </v-dialog>

</v-container>

</template>
<script lang="ts">
import axios from 'axios';

import printing from '@/plugins/print'
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {  
  data() {
    return {
      headers: [
        { text: '',value: 'name',align:'start',sortable: false,},   
        // { text: 'المشروع',value: 'project_name',align:'start',sortable: false,},  
        // { text: 'المدفوع', value: 'paid',align:'end', sortable: false },
        // { text: 'المتبقي', value: 'rest',align:'end', sortable: false },
        // { text: 'الكلي', value: 'total',align:'end', sortable: false },
      ], 
            search:'',
            dialogPrint:false,
            Tdata:[],
            sum_report:{},
            groupTypes:[
                {title:'الشخص',value:'person_id'},
                {title:'مرحلة العمل',value:'construction_category_id '},
                {title:'التبويب',value:'expense_type_id'}
            ],
            completionTypes:[
                {title:'غير مكتمل',value:'0'},
                {title:' مكتمل',value:'1'},
                {title:'الكل',value:''},

           ], 
            SearchParams:{
                group_type:'expense_type_id',
                project_is_complete:'1',

                expense_type_id:'',
                construction_category_id:'',
                person_id:'',
                project_id:'',
            },
            printingArray:[ { text:"table" , name:'الجدول'},{ text:"footer" , name:'المجموع'}  ],

        }
    },
    computed:{
      ...mapGetters([  'projects_n'  , 'constructions', 'contructors_n', 'expensesType' ]), 
    },
    created(){
        this.fetchDataOfExpensesGroup()

        this.f_projects_n()
        this.f_constructors_n()
        this.f_constructions()
        this.f_expensesType()
    },
    methods: {
      ...mapActions([ 'f_projects_n' , 'f_constructors_n', 'f_constructions', 'f_expensesType']),
       

      // sortingData(data:any){
      //   data.forEach(element => {
      //     return element.forEach(e => {
      //       return e.project_name
      //     });
      //   });
      // },
      print(){
        this.dialogPrint = true;
            printing(this.printingArray)
            setTimeout(()=>{

              this.dialogPrint = false;
            },200)
      },
      
      fetchDataOfExpensesGroup(){
        axios.post('a/report/group_by_expense ',this.SearchParams)
        .then(res => {
            console.log(res)
            this.Tdata=res.data.report
            this.sum_report=res.data.final_report


          //  this.sortingData(this.Tdata)
        })
        .catch(err => {
            console.error(err); 
        })
      }  
    },
}
</script>
<style  >
    
</style>