import axios from "axios";


const actions = {
    deleteCard({
        dispatch
    }, card) {
        axios.get("a/" + card.type + "/delete/" + card.id)
            .then(() => {
                dispatch('oprationSuccess', 'تم الحذف بنجاح')

                if (card.type == 'constructor-type')
                    dispatch('f_constructorTypes')
                else if (card.type == 'emp-type')
                    dispatch('f_employeeTypes')
                else if (card.type == 'con-cat') {
                    dispatch('f_constructions')
                    dispatch('f_constructionsTree')
                } else if (card.type == 'exp-type')
                    dispatch('f_expensesType')
                else if (card.type == 'meas')
                    dispatch('f_guessProject', card.main_id)
                else if (card.type == 'daily-position')
                    dispatch('f_dailyPosition')
                else
                    dispatch('f_' + card.type)

 
            })
            .catch(err => {
                console.error(err);

                dispatch('oprationFailed', 'لم يتم الحذف')
            })
    },
    updateCard({
        dispatch
    }, card) {
        axios.post("a/" + card.type + "/update/" + card.id, card.data)
            .then(() => {
                dispatch('oprationSuccess', 'تم التعديل بنجاح')

                if (card.type == 'constructor-type')
                    dispatch('f_constructorTypes')
                else if (card.type == 'emp-type')
                    dispatch('f_employeeTypes')
                else if (card.type == 'con-cat') {
                    dispatch('f_constructions')
                    dispatch('f_constructionsTree')
                } else if (card.type == 'exp-type')
                    dispatch('f_expensesType')
                else if (card.type == 'meas')
                    dispatch('f_guessProject', card.main_id)
                else if (card.type == 'safe')
                    dispatch('f_safes')
                else
                    dispatch('f_' + card.type)
            })
            .catch(err => {
                console.error(err);

                dispatch('oprationFailed', 'لم يتم التعديل')
            })

    },
    addCard({
        dispatch
    }, card) {
        axios.post("a/" + card.type + "/add", card.data)
            .then(() => {
                dispatch('oprationSuccess', 'تم الاضافة بنجاح') 
                if (card.type == 'constructor-type') dispatch('f_constructorTypes')
                else if (card.type == 'emp-type') dispatch('f_employeeTypes')
                else if (card.type == 'con-cat') {
                    dispatch('f_constructions')
                    dispatch('f_constructionsTree')
                } else if (card.type == 'exp-type')
                    dispatch('f_expensesType')
                else if (card.type == 'safe')
                    dispatch('f_safes')
                else if (card.type == 'meas')
                    dispatch('f_guessProject', card.main_id)
                else dispatch('f_' + card.type)


            })
            .catch(err => {
                console.error(err); 
                dispatch('oprationFailed', 'لم يتم الاضافة')
            })
    },
    deletePersonImage({
        dispatch
    }, id) {
        axios.get('a/per-img/delete/' + id)
            .then(() => {
                dispatch('oprationSuccess', 'تم الحذف بنجاح')

            })
            .catch(err => {
                console.error(err);

                dispatch('oprationFailed', 'لم يتم الحذف')
            })
    },
    uploadPersonImage({
        dispatch
    }, FormData) {
        let id = FormData.get('id')
        FormData.delete('id')
        axios.post('a/per-img/add/' + id, FormData)
            .then(() => {
                dispatch('oprationSuccess', 'تم اضافة الصوره بنجاح')

            })
            .catch(err => {
                console.error(err);
                dispatch('oprationFailed', 'لم يتم الاضافة')

            })
    },
    uploadPersonDoc({
        dispatch
    }, formData) {
        axios.post('a/dcm/add', formData)
            .then(() => {
                dispatch('oprationSuccess', 'تم الاضافة بنجاح')

            })
            .catch(err => {
                console.error(err);
                dispatch('oprationFailed', 'لم يتم الاضافة')
            })
    },
    deleteDoc({
        dispatch
    }, id) {
        axios.get('a/dcm/delete/' + id)
            .then(() => {
                dispatch('oprationSuccess', 'تم الحذف بنجاح')

            })
            .catch(err => {
                console.error(err);
                dispatch('oprationFailed', 'لم يتم الحذف')

            })
    },


}


export default {
    actions
}