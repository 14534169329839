<template>
<v-container fluid>
    <h2 class="text-center my-2">سلف الاعمال </h2>
    <v-dialog v-model="dialogSorting" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                البحث حسب
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field outlined v-model="searchExpenses.title" :rules="rules.required" required label="الفقرة" reverse>
                            </v-text-field>
                        </v-col>

                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete small outlined auto-select-first reverse v-model="searchExpenses.is_cash" :items="cash" item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete outlined v-model="searchExpenses.person_id" :items="employees_n" required item-text="name" item-value="id" label="المستلم">
                            </v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete outlined v-model="searchExpenses.is_complete" :items="complete" item-text="name" item-value="id" label="مكتمل">
                            </v-autocomplete>
                        </v-col>
                        <v-col dir="ltr" cols="12" sm="6" md="4">
                            <v-autocomplete reverse outlined chips multiple v-model="searchExpenses.user_ids" :items="users" item-text="name" item-value="id" label="مستخدم"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.from" label=" من تاريخ" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field reverse outlined v-model="searchExpenses.to" label="الى تاريخ" type="date"></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogSorting=!dialogSorting" dark color="red">الغاء</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="searchEx()" color="success">بحث</v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
    <template>
        <!-- main table -->
        <v-data-table :headers="headers" :search="search" show-expand :items="expenses" show-group-by class="my_btn bg-main" :footer-props="{
        'items-per-page-text':'عدد العناصر',
            'next-icon':'$prev',
            'prev-icon':'$next', 
        }">
            <template v-slot:top>
                <v-toolbar flat class=" bg-main">
                    <!-- header -->
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="success" small class="my_btn" @click="dialogSorting=!dialogSorting">
                        <v-icon>mdi-sort-bool-ascending-variant</v-icon> البحث التفصيلي
                    </v-btn>
                    <v-spacer></v-spacer>

                    <!-- search -->
                    <v-text-field reverse v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <!-- add and delete dialog -->
                    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn reverse color="blue" dark class="mb-2 my_btn elevation-0" v-bind="attrs" v-on="on"
                            @click="checkPermissions('expense-add')"
                            >أضافة<v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <!-- print btn -->
                            <v-btn class="mb-2 mx-2 my_btn elevation-0" color="main" @click="print()">
                                <v-icon color="blue">mdi-printer-outline</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <!-- dialog add and edit  -->
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field outlined v-model="editedItem.title" :rules="rules.required" required label="الفقرة" reverse>
                                            </v-text-field>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete outlined auto-select-first reverse v-model="editedItem.is_cash" :items="cash" :rules="[v => (!!v || v==0 )|| 'ادخل الدفع']" required item-text="name" item-value="id" label="نوع الدفع"></v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4">
                                            <v-autocomplete outlined v-model="editedItem.is_complete" reverse :items="complete" :rules="[v => (!!v || v==0 ) || 'ادخل الحقل']" required item-text="name" item-value="id" label="مكتمل">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col dir="ltr" cols="12" sm="6" md="4" v-if="editedIndex==-1">
                                            <v-autocomplete reverse outlined v-model="editedItem.person_id" :items="employees_n" required item-text="name" item-value="person_id" label="المستلم">
                                            </v-autocomplete>
                                        </v-col>

                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" large text @click="close"> إلغاء </v-btn>
                                <v-btn color="green white--text" large :disabled="!formIsValid" type="submit" @click="save"> حفظ </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <!-- delete dialog -->
                            <v-card-title text class="text-h6">هل تريد حذف العنصر بشكل نهائي</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeDelete">الغاء</v-btn>
                                <v-btn color="green darken-1" text @click="deleteItemConfirm">موافق</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            >
            <template v-slot:[`group.header`]="{headers, isOpen, toggle ,group,remove}">
                <th class="text-right" :colspan="headers.length">
                    <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{group}}
                    <v-icon @click="remove">mdi-close</v-icon>
                </th>
            </template>
            <!-- more detials -->
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    تم الادخال بواسطة: <b class="mx-1"> {{item.user.name}} </b>
                    التاريخ: <b class="mx-1">{{item.created_at[1].slice(0,10)}}</b>
                    المدفوع: <v-chip class="mx-4" outlined color="green"><b class="mx-1">{{item.paid}}</b></v-chip>
                    المتبقي: <v-chip class="mx-4" outlined color="red"> <b class="mx-1">{{item.rest}}</b></v-chip>
                </td>
            </template>
            <!-- time -->
            


            <template v-slot:[`item.is_complete`]="{item}">
                <v-chip small color="green" text-color="white" v-if="item.is_complete">مكتمل</v-chip>
                <v-chip small color="lime" v-else>غير مكتمل</v-chip>
            </template>
            <!-- type  -->
            <template v-slot:[`item.is_cash`]="{item}">
                <v-chip small color="red" text-color="white" v-if="!item.is_cash">آجل</v-chip>
                <v-chip small color="cyan" text-color="white" v-else>نقد</v-chip>
            </template>
            <!-- actions of table -->
            <template v-slot:[`item.operation`]="{item}">
                <v-icon color="" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
                <v-icon color="blue" @click="openExpense(item.id)"> mdi-open-in-new</v-icon>
            </template>
            <template v-slot:no-data>
                لا توجد بيانات
            </template>
        </v-data-table>
        <v-row class="my-5 text-center">
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="error" class="my_btn" border="right">
                    المبلغ الكلي المطلوب: {{expensesReport.rest_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="success" class="my_btn" border="right">
                    المبلغ الكلي المدفوع: {{expensesReport.paid_sum}}
                </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="elevation-0">
                <v-alert type="info" class="my_btn" border="right">
                    المبلغ الكلي : {{expensesReport.total_export_sum}}
                </v-alert>
            </v-col>
        </v-row>
    </template>

    <!-- to print -->
    <v-dialog v-model="dialogPrint">
        <v-btn @click="dialogPrint=!dialogPrint" large color="error">اغلاق</v-btn>
        <h3 v-if="expenses" id="p_title" class="p_center"> سلف الاعمال </h3>

        <div>
            <v-data-table id="table" :headers="headersPrint" :search="search" :items="expenses" class="elevation-1" hide-default-footer disable-pagination>
                <template v-slot:[`item.is_complete`]="{item}">
                    <div v-if="item.is_complete">مكتمل</div>
                    <div v-else>غير مكتمل</div>
                </template>
                <template v-slot:[`item.is_cash`]="{item}">
                    <div v-if="item.is_cash">نقد</div>
                    <div v-else>أجل</div>
                </template>

            </v-data-table>
        </div>
    </v-dialog>
</v-container>
</template>

<script>
 import printing from '@/plugins/print'
import tools from '@/plugins/tools'
 import router from '@/router'
 import {
     mapActions,
     mapGetters
 } from 'vuex'
 export default {
     name: 'employeeInvoice',
     data: () => ({
         dialog: false,
         dialogDelete: false,
         dialogPrint: false,
         dialogSorting: false,
         search: '',
         rules: {
             required: [val => (val || '').length > 0 || 'ادخل الحقل '],
         },
         cash: [{
             id: 0,
             name: 'اجل'
         }],
         complete: [{
             id: 0,
             name: 'غير مكتمل'
         }, {
             id: 1,
             name: 'مكتمل'
         }],
         headers: [{
                 text: 'الفقرة',
                 value: 'title',
                 align: 'start',
                 groupable: false,
             },
             {
                 text: 'الدفع',
                 value: 'is_cash'
             },
             {
                 text: 'مكتمل',
                 value: 'is_complete',
                 groupable: true
             },
             {
                 text: 'المستلم',
                 value: 'person.name',
                 groupable: true
             },
             {
                 text: 'تاريخ',
                 value: 'first_invoice_date'
             },
             {
                 text: 'الكلي',
                 value: 'total',
                 groupable: false
             },

             {
                 text: ' العمليات',
                 value: 'operation',
                 sortable: false,
                 groupable: false
             },
         ],
         headersPrint: [{
                 text: 'الفقرة',
                 value: 'title',
                 sortable: false
             },
             {
                 text: 'مكتمل',
                 value: 'is_complete',
                 sortable: false
             },
             {
                 text: 'المستلم',
                 value: 'person.name',
                 sortable: false
             },
             {
                 text: 'المبلغ ',
                 value: 'total',
                 sortable: false
             },
             {
                 text: 'المدفوع',
                 value: 'paid',
                 sortable: false
             },
             {
                 text: 'المتبقي',
                 value: 'rest',
                 sortable: false
             },
         ],
         editedIndex: -1,
         editedItem: {
             title: '',
             is_cash: 0,
             is_complete: 0,
             construction_category: {
                 id: ''
             },
             project_id: '',
             expense_type: {
                 id: ''
             },
             person_id: '',
             person: {
                 id: ''
             },
             count: 0,
             unit_price: 0,
         },
         defaultItem: {
             title: '',
             is_cash: 0,
             is_complete: 0,
             construction_category: {
                 id: ''
             },
             project_id: '',
             expense_type: {
                 id: ''
             },
             person_id: '',
             person: {
                 id: ''
             },
             count: 0,
             unit_price: 0,
         },
         searchExpenses: {
             is_cash: '',
             is_complete: '',
             construction_category_id: '',
             project_id: '',
             expense_type_id: '',
             person_id: '',
             user_id: '',
             from: '',
             to: '',
         },
         searchExpensesDefault: {
             is_cash: '',
             is_complete: '',
             construction_category_id: '',
             project_id: '',
             expense_type_id: '',
             person_id: '',
             user_id: '',
             from: '',
             to: '',
         }
     }),

     computed: {
         ...mapGetters(['users', 'expenses', 'employees_n', 'expensesReport']),

         formTitle() {
             return this.editedIndex === -1 ? 'أضافة جديد' : 'تعديل'
         },
         formIsValid() {
             return (
                 Boolean(this.editedItem.title) &&
                 Boolean(this.editedItem.is_cash || this.editedItem.is_cash == 0) &&
                 Boolean(this.editedItem.is_complete || this.editedItem.is_complete == 0)
             )
         }
     },

     watch: {
         dialog(val) {
             val || this.close()
         },
         dialogDelete(val) {
             val || this.closeDelete()
         },
     },

     mounted() {
      tools.checkPermissions('expense-search')
         let params = {}
         params.expense_type_id = 8;
         this.f_expenses(params)
         this.f_user();
         this.f_employees_n()
     },
     methods: {
         ...mapActions(['f_user', 'f_expenses', 'f_employees_n', 'addExpenses', 'addExpensesCash', 'editExpenses', 'deleteExpenses']),
         openExpense(id) {
             router.push('/expensesDetials/' + id)
         },
         searchEx() {
             this.searchExpenses.expense_type_id = 8;

             if (this.searchExpenses.is_cash === 0)
                 this.searchExpenses.is_cash = '0'
             if (this.searchExpenses.is_complete === 0)
                 this.searchExpenses.is_complete = '0'

             this.f_expenses(this.searchExpenses)
             this.dialogSorting = false
             this.searchExpenses = Object.assign({}, this.searchExpensesDefault)
         },
         print() {
             this.dialogPrint = true;
             printing(['p_title', "table"])
         },
         checkPermissions(per){tools.checkPermissions(per)},

         editItem(item) {
            this.checkPermissions('expense-update') 
             this.editedIndex = this.expenses.indexOf(item)
             this.editedItem = Object.assign({}, item)
             this.editedItem.person_id = this.editedItem.person.id
             this.dialog = true
         },

         deleteItem(item) {
            this.checkPermissions('expense-delete') 
             this.editedIndex = this.expenses.indexOf(item)
             this.editedItem.id = item.id
             this.dialogDelete = true
         },
         deleteItemConfirm() {
             let params = {}
             params.expense_type_id = 8;
             this.editedItem.fetch = params

             this.deleteExpenses(this.editedItem)
             this.closeDelete()
         },

         close() {
             this.dialog = false
             this.$nextTick(() => {
                 this.editedItem = Object.assign({}, this.defaultItem)
                 this.editedIndex = -1
             })
         },
         closeDelete() {
             this.dialogDelete = false
             this.$nextTick(() => {
                 this.editedItem = Object.assign({}, this.defaultItem)
                 this.editedIndex = -1
             })
         },

         save() { //save dialog add and edit 
             this.editedItem.expense_type_id = 8

             let params = {}
             params.expense_type_id = 8;
             this.editedItem.fetch = params

             if (this.editedIndex > -1) {
                 //update item    
                 this.editExpenses(this.editedItem)
             } else {
                 //add new item  
                 if (this.editedItem.is_cash) {
                     this.editedItem.count = "1"

                     this.addExpensesCash(this.editedItem)
                 } else
                     this.addExpenses(this.editedItem)

             }
             this.close()
         },

     },
 }
</script>
