<template>
  <div>
    <v-text-field outlined reverse v-model="formattedCurrencyValue" label="المبلغ"   @blur="focusOut" @focus="focusIn"></v-text-field>
  </div>
</template>
<script>
export default {
  data(){
    return{  
      write_total:'',
        formattedCurrencyValue: "0"
    } 
  }, 
   props:{
      reset:Boolean
    }, 
    watch:{
      reset:function (value){
        console.log('value',value);
        if(value){
          this.write_total = '0'
          this.formattedCurrencyValue = this.write_total.toLocaleString('en-US')
          this.$emit('newValue',this.write_total ) 
        }
      }
    },
  methods:{
    focusOut(){ 
            
            if (typeof this.formattedCurrencyValue === 'string') {
              this.write_total = parseFloat(this.formattedCurrencyValue.replace(/[^\d-]/g, "")) 
            }

            if (isNaN(this.write_total)) {
                this.write_total = '0'
            } 
            
            this.formattedCurrencyValue = this.write_total.toLocaleString('en-US')

            this.$emit('newValue',this.write_total )
            console.log( 'this.write_total :',this.write_total);
        },
        focusIn(){ 
            this.formattedCurrencyValue = this.write_total
        },
  }
}

</script>