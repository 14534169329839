<template>
    <v-container fluid>
         <v-row>
            <v-col  >
              <pie-chart v-if="projectExpenscesTypeReport.lables" :myData="projectExpenscesTypeReport"/>
            </v-col>
            <v-col>
              <pie-chart v-if="projectConstructionsReport.lables" :myData="projectConstructionsReport"/>
            </v-col>
         </v-row> 
         <v-row>
          <v-col> 
            <table id="customers">
                    <tr>
                        <th>التبويب </th>
                        <th>المبلغ المصروف</th> 
                    </tr>
                    <tr v-for="( item , index ) in projectExpenscesTypeReport.lables" :key="index"> 
                        <td>{{item}}</td>  
                        <td>{{projectExpenscesTypeReport.values[index]}}</td>  
                    </tr>  
                </table>
          </v-col>
          <v-col> 
            <table id="customers">
                    <tr>
                        <th>مرحلة العمل</th>
                        <th>المبلغ المصروف</th> 
                    </tr>
                    <tr v-for="( item , index ) in projectConstructionsReport.lables" :key="index"> 
                        <td>{{item}}</td>  
                        <td>{{projectConstructionsReport.values[index]}}</td>  
                    </tr>  
                </table>
          </v-col>
         </v-row>
    </v-container>
</template>
<script> 
import pieChart from '@/views/reports/pieChart.vue'
import { mapActions, mapGetters } from 'vuex'
import tools from '@/plugins/tools'
export default {
  components: { pieChart },
    props:['id'], 
    name:'projectReport',
    computed:mapGetters(['projectExpenscesTypeReport','projectConstructionsReport']),
    methods:{
        ...mapActions(['f_projectReport'])
    }
    ,
    created(){
        tools.checkPermissions('expense-report')
        this.f_projectReport(this.id)
    }
}
</script>

<style scoped>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px; 
    background-color: #04AA6D;
    color: white;
}
</style>
