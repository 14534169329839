import axios from "axios"
const state = {
    guessProject: [], 
    guessProjects: [], 

}

const getters = {
    guessProject: (state) => state.guessProject, 
    guessProjects: (state) => state.guessProjects, 

}

const mutations = {
    setGuessProject: (state, guessProject) => state.guessProject = guessProject, 
    setGuessProjects: (state, guessProjects) => state.guessProjects = guessProjects, 
}
const actions = { 
    f_guessProject({commit},id){
        axios.get('a/guess-project/show/'+id)
        .then(res => {
            
            commit('setGuessProject',res.data)
        })
        .catch(err => {
            console.error(err); 
        })
    },
    f_guessProjects({commit}){
        axios.get('a/guess-project/index')
        .then(res => {
            
            commit('setGuessProjects',res.data.guess_projects)
        })
        .catch(err => {
            console.error(err); 
        })
    }
}

export default {
    getters,
    state,
    actions,
    mutations
}