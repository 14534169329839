<template>
  <v-container class="fs-2" fluid>
    <v-col>
      <v-btn
        @click="addProject()"
        rounded
        dark
        class="mx-3 my_btn elevation-0 bg-main success--text"
        ><v-icon>mdi-office-building-cog</v-icon>أنشاء مشروع تخميني</v-btn
      >
    </v-col>
    <v-data-iterator
      :items="guessProjects"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      hide-default-footer
    >
      <template v-slot:header>
        <v-divider class="mx-4" inset vertical></v-divider>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="bg-main my_btn">
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }}
                <v-spacer class="white--text"></v-spacer>
                <v-btn
                  icon
                  class="my_btn"
                  :to="'/guessProject/' + item.id"
                  color="success aline-end"
                  ><v-icon>mdi-book-arrow-left</v-icon></v-btn
                >
                 <v-btn
                  icon
                  class="my_btn"
                  small
                  @click="editProject(item)"
                  color="blue aline-end"
                  ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
                >
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <span class="grey--text">عدد المشاريع في الصفحة</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            {{ page }} من {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="green darken-1"
            class="mx-2"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn fab dark class="mx-2" color="green darken-1" @click="nextPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="modal" transition="dialog-bottom-transition">
      <v-card v-if="projectType == 'add'">
        <v-card-title> اضافة مشروع جديد </v-card-title>
        <v-card-text>
          <v-text-field
            label="اسم المشروع"
            v-model="projectName"
            outlined
            reverse
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions> <v-btn @click="addProjectOk" color="success">اضافة</v-btn> </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-card-title> تعديل اسم المشروع </v-card-title>
        <v-card-text>
          <v-text-field
            label="اسم المشروع"
            v-model="projectName"
            outlined
            reverse
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions> <v-btn @click="editProjectOk" color="blue">تعديل</v-btn> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "guessProjects",
  data() {
    return {
      search: "",
      modal: false,
      projectType: "",
      projectName: "",
      project_id:'',
      itemsPerPageArray: [4, 8, 12],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      keys: ["Name"],
    };
  },
  computed: {
    ...mapGetters(["guessProjects"]),
    numberOfPages() {
      return Math.ceil(this.guessProjects.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    ...mapActions(["f_guessProjects"]),
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    addProject() {
      this.projectName = "";
      this.projectType = "add";
      this.modal = true;
    },
    addProjectOk(){
      axios.post('a/guess-project/add',{name:this.projectName})
      .then(() => {
        
        this.f_guessProjects()
        this.modal = false;
      })
      .catch(err => {
        console.error(err); 
      })
    },
     editProject(project) {
      this.projectName = project.name;
      this.project_id=project.id
      this.projectType = "edit";
      this.modal = true;
    },
    editProjectOk(){
      axios.post('a/guess-project/update/'+this.project_id,{name:this.projectName})
      .then(() => {
        
        this.f_guessProjects()
        this.modal = false;
      })
      .catch(err => {
        console.error(err); 
      })
    }
  },

  mounted() {
    this.f_guessProjects();
  },
};
</script>
