<template>
<div>
    <v-container>
        <h3 class="my-5 text-center">تعديل بيانات المشروع</h3>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.name" required label="الاسم"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.address" required label="العنوان"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.start_date" label="تاريخ البدء" type="date"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4">
          <v-text-field
            reverse
            outlined
            v-model="project.end_date"
            label="تاريخ الانتهاء"
            type="date"
          ></v-text-field>
        </v-col> -->
            <!-- <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.profit" label="نسبة الفائدة" type="number">
                </v-text-field>
            </v-col> -->

            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.quantity" type="number" label="المساحة m2">
                </v-text-field>
            </v-col>
            <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete outlined auto-select-first reverse v-model="project.project_type_id" :items="projectType" :rules="[(v) => !!v || 'ادخل نوع المشروع ']" required item-text="name" item-value="id" label="نوع المشروع"></v-autocomplete>
            </v-col>
            <v-col dir="ltr" cols="12" sm="6" md="4">
                <v-autocomplete outlined auto-select-first reverse multiple v-model="project.engineer" :items="engineers_n" required item-text="name" item-value="id" label="مهندس المشروع">
                </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.expense_limit" type="number" label="مبلغ الاعتماد">
                </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.project_duration" type="number" label="مدة المشروع "></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.end_date" type="date" label="تاريخ اكتمال المشروع "></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.estimated_cost" type="number" label="التکلفة التخمینیة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field reverse outlined v-model="project.project_number"   label="رقم المشروع">
                </v-text-field>
            </v-col> 

            <v-col cols="12" sm="6" md="4" dir="rtl">
                <v-autocomplete reverse outlined v-model="project.is_complete" :items="[ {id:'1', name:'المكتمله'},{id:'0', name:'غير مكتمل'} ]" item-text="name" item-value="id" label="الانجاز "></v-autocomplete>

                 <!-- <v-checkbox color="success" v-model="project.is_complete" large label="مكتمل"></v-checkbox> -->
            </v-col>

            <v-col cols="12">
                <v-text-field reverse outlined v-model="project.notes" required label="الملاحظات"></v-text-field>
            </v-col>

            <v-alert class="mx-2 my-2" type="error" v-if="inputError">
                خطأ في بعض المدخلات
            </v-alert>
        </v-row>
    </v-container>
    <v-btn @click="next()" class="text-start mx-3" color="success">حفظ البيانات</v-btn>
</div>
</template>

<script>
import axios from "axios";
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "editProjectInfo",
    data() {
        return {
            inputError: false,
            id: '',
            // project: {
            //     name: "",
            //     address: "",
            //     start_date: "",
            //     profit: "",
            //     quantity: "",
            //     project_type_id: 1,
            //     notes: "",
            //     end_date: '',
            //     engineer_id: '',
            //     expense_limit: '',
            //     project_duration: '',
            //     estimated_cost: '',
            //     project_number: '',
            //     is_complete:0,

            // },
            projectType: [{
                    id: 3,
                    name: "اشراف"
                },
                {
                    id: 2,
                    name: "تنفيذ"
                },
                {
                    id: 1,
                    name: "تصميم"
                },
            ],
        };
    },
    mounted() {
        this.f_engineers_n()
        this.f_project(this.id)
    },
    created() {
        this.id = this.$route.params.id
    },
    computed: mapGetters(['engineers_n', 'project']),

    methods: {
        ...mapActions(['f_engineers_n', 'f_project']),
        next() {
            console.log();


            if(typeof this.project.engineer[0] === 'object'){
               this.project.engineer= this.project.engineer.map(e => e.id)
            }

            console.log(this.project.engineer);
           
            axios
                .post("a/project/update/" + this.id , this.project)
                .then(() => { 
                    window.history.back();
                })
                .catch((err) => {
                    console.error(err);
                    this.inputError = true;
                });
        },
    },
};
</script>
